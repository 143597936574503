import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-categories',
  templateUrl: './icon-categories.component.html',
  styleUrls: ['./icon-categories.component.css']
})
export class IconCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
