import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/shared/services/category.service';
import { DatabankService } from 'src/app/shared/services/databank.service';

declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  errorMessages: any = {};

  categoryParams: any = {};

  isCategoryFormSubmitted: boolean = false;

  @ViewChild('categoryForm') categoryForm: any;

  isUpdating: boolean = false;
  shouldReloadTable: boolean = false;


  constructor(private databankService: DatabankService, private categoryService: CategoryService, private toastrService: ToastrService) {
    this.errorMessages = databankService.messages;
   }

  ngOnInit() {

  }

  resetForm(){
    this.categoryParams = {};
    this.isCategoryFormSubmitted = false;
    this.isUpdating = false;
  }

  createCategory() {
    this.isCategoryFormSubmitted = true;

    const model = Object.assign({}, this.categoryParams);

    if (this.categoryForm.form.valid) {
      if (this.isUpdating) {
        delete model.lastUpdatedDate;
        delete model.dateCreated;
        delete model.courses;

        this.categoryService.updateCategory(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#categoryModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.categoryParams = {};
            }, 1000);
          }
        });
      } else {
        this.categoryService.createCategory(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#categoryModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.categoryParams = {};
            }, 1000);
          }
        });
      }
    }
  }

  getCategory(value: any): void {
    if (value) {
      this.categoryParams = value;
      this.isUpdating = true;
      $('#categoryModal').modal('show');
    }
  }

  openCategoryModal(value: any) {
    if (value) {
      this.categoryParams = value;
      this.isUpdating = true;
      $('#categoryModal').modal('show');
    }
  }

}
