import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  showSingleProductMenu: boolean = false;
  showProductPackMenu: boolean = false;
  user: any = {};

  @Input() hasNavigatedToSinglePage: boolean = false;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    // this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnChanges(): void {
    if (this.hasNavigatedToSinglePage) {
      this.showSingleProductMenu = false;
      this.showProductPackMenu = false;
    }
  }

  toggleSingleProductMenu() {
    this.showSingleProductMenu = !this.showSingleProductMenu;
    this.showProductPackMenu = false;
  }

  toggleProductPackMenu() {
    this.showProductPackMenu =  !this.showProductPackMenu;
    this.showSingleProductMenu = false;
  }

  logout() {
    // this.authService.logout().subscribe((response: any) => {
    //   if (response.status) {
    //     this.router.navigate(['/login']);
    //     localStorage.clear();
    //   }
    // })
  }

}
