import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatabankService } from 'src/app/shared/services/databank.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginParams: any = {};
  passwordChangeParams: any = {};
  resetPasswordParams: any = {};

  isFieldOneTextVisible: boolean = false;
  isFieldTwoTextVisible: boolean = false;
  isFieldThreeTextVisible: boolean = false;

  isLoginFormSubmitted: boolean = false;
  isPasswordChangeFormSubmitted: boolean = false;
  isResetPasswordFormSubmitted: boolean = false;

  isRequiredToChangePassword: boolean = false;
  isForgotPassword: boolean = false;
  isVerificationCodeSent: boolean = false;

  errorMessages: any = {};
  // Forms
  @ViewChild('loginForm') loginForm: any;
  @ViewChild('passwordChangeForm') passwordChangeForm: any;
  @ViewChild('resetPasswordForm') resetPasswordForm: any;

  constructor(private authService: AuthService, private databankService: DatabankService, private toastrService: ToastrService,
    private router: Router) {
    this.errorMessages = databankService.messages;
  }

  ngOnInit(): void {  }

  authenticateUser(): void {
    this.isLoginFormSubmitted = true;

    if (this.loginForm.form.valid) {
      this.authService.login(this.loginParams).subscribe((response: any) => {
        if (response.code == 0) {

          this.isRequiredToChangePassword = false;
          localStorage.setItem('user', JSON.stringify(response.data));
          this.router.navigate(['/dashboard']);

        } else if (response.code == 97) {

          this.isRequiredToChangePassword = true;
          this.toastrService.info(response.message, 'Password Change', {closeButton: true});
          localStorage.setItem('user', JSON.stringify(response.data));

        }
      })
    }
  }

  togglePasswordText(option: any) {
    if (option == 1) {
      this.isFieldOneTextVisible = !this.isFieldOneTextVisible;
    } else if (option == 2) {
      this.isFieldTwoTextVisible = !this.isFieldTwoTextVisible;
    } else if (option == 3) {
      this.isFieldThreeTextVisible = !this.isFieldThreeTextVisible;
    }
  }

  changePassword(): void {
    this.isPasswordChangeFormSubmitted = true;

    if (this.passwordChangeForm.form.valid) {
      this.passwordChangeParams.currentPassword = this.loginParams.password;
      this.authService.changePassword(this.passwordChangeParams).subscribe((response: any) => {
        if (response.code == 0) {
          this.router.navigate(['/dashboard']);
        }
      })
    }
  }

  returnToLogin(): void {
    this.isRequiredToChangePassword = false;
  }

  toggleForgotPassword(): void {
    // this.resetPasswordParams.email = this.loginParams.email;
    this.isForgotPassword = !this.isForgotPassword;
  }

  sendVerificationCode(): any {
    if (this.isVerificationCodeSent) {
      this.authService.sendVerificationCode(this.resetPasswordParams).subscribe((response: any) => {
        if (response.code == 0) {
          this.toastrService.success(response.message, 'Success!');
          this.isVerificationCodeSent = true;
          this.isResetPasswordFormSubmitted = false;
        }
      });
    } else {
      this.isResetPasswordFormSubmitted = true;
      if (this.resetPasswordForm.form.valid) {
        this.authService.sendVerificationCode(this.resetPasswordParams).subscribe((response: any) => {
          if (response.code == 0) {
            this.toastrService.success(response.message, 'Success!');
            this.isVerificationCodeSent = true;
            this.isResetPasswordFormSubmitted = false;
          }
        });
      }
    }

  }

  resetPassword(): void {
    const that = this;
    this.isResetPasswordFormSubmitted = true;
    if (this.resetPasswordForm.form.valid) {
      this.authService.resetPassword(this.resetPasswordParams).subscribe((response: any) => {
        if (response.code == 0) {
          this.isForgotPassword = false;
          this.isVerificationCodeSent = false;
          this.isResetPasswordFormSubmitted = false;
          this.toastrService.success(response.message, 'Success!', {closeButton: true});

          setTimeout(() => {
            that.resetPasswordParams = {};
          }, 1000);
        }
       })
    }
  }

}
