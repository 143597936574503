import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResourcesRoutingModule } from './resources-routing.module';
import { ResourcesComponent } from './resources.component';
import { ResourceListComponent } from 'src/app/features/resource-list/resource-list.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { DeleteModalComponent } from 'src/app/features/delete-modal/delete-modal.component';


@NgModule({
  declarations: [ResourcesComponent, ResourceListComponent, DeleteModalComponent],
  imports: [
    CommonModule,
    ResourcesRoutingModule,
    SharedModule
  ], exports: [
    DeleteModalComponent
  ]
})
export class ResourcesModule { }
