import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatabankService {
  messages: any = {};

  constructor() {
    this.messages = {
      requiredField: 'This field is required',
      validEmail: 'Input a valid email address',
      validNumber: 'Input a number',
      passwordMismatch: 'Password mismatch'
    }
   }
}
