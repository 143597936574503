import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingEventService {

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private datatableService: DatatableService) { }

  createTrainingEvent(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'training-events/staff/create', request);
  }

  updateTrainingEvent(request: any): Observable<any> {
    return this.http.put(this.baseUrl + 'training-events/staff/update', request);
  }

  getTrainingEvent(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl}training-events/find/${id}/open`);
  }

  getTrainingEvents(request: any): Observable<any> {
    return this.datatableService.getList(this.baseUrl + 'training-events/list/open', request);
  }

  deleteTrainingEvent(request: any): Observable<any> {
    return this.http.request('delete', `${this.baseUrl}training-events/staff/delete`, {body: request});
  }

  addNotes(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'training-events/staff/add-resource', request);
  }
}
