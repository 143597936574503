import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class TokenizerInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = JSON.parse(localStorage.getItem('user') || '{}');

    if (Object.keys(auth).length === 0 && auth.constructor === Object) {
      return next.handle(req);
    } else {

      const token = auth.token;
      const modifiedRequestHeaders = req.clone({
        headers: req.headers
        .set('Authorization', token)
      });
      return next.handle(modifiedRequestHeaders);
    }

  }
}
