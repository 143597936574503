<header class="topbar d-sm-block d-md-none">
  <nav class="navbar top-navbar navbar-expand-md navbar-light">
      <div class="navbar-header">
          <!-- This is for the sidebar toggle which is visible on mobile only -->
          <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)" (click)="toggleSidebar()">
              <i class="ti-menu ti-close"></i>

          </a>
          <div class="navbar-brand d-flex justify-content-start">
              <!-- <p>Say something </p> -->
              <div class="navbar-brand d-flex">
                <span class="bold-text">Hi {{user.firstName}}!</span>
            </div>

            <div class="d-flex justify-content-between ms-auto align-items-center" style="margin-right: 15px; width: 220px; height: 70px;">
              <!-- <app-icon-bell class="pointer"></app-icon-bell> -->
              <!-- <app-icon-gear class="pointer" (click)="logout()"></app-icon-gear> -->
              <img src="../../../assets/images/icons/logout.svg" alt="" class="header-icons" (click)="logout()">

              <div class="img-container">
                <img src="../../../assets/images/user-thumbnail.png" class="rounded-image" />
              </div>
            </div>
          </div>
      </div>
  </nav>
</header>

<header class="topbar d-none d-md-block" style="margin-left: 250px;">
  <nav class="navbar top-navbar navbar-expand-md navbar-light">
    <div class="navbar-brand d-flex">
        <span class="bold-text">&nbsp;&nbsp;&nbsp; Hi {{user.firstName}}!</span>
    </div>

    <div class="d-flex justify-content-between ms-auto align-items-center" style="margin-right: 15px; width: 120px; height: 70px;">
      <!-- <app-icon-bell class="pointer"></app-icon-bell> -->
      <img src="../../../assets/images/icons/logout.svg" alt="" class="header-icons" (click)="logout()">
      <!-- <app-icon-gear class="pointer" (click)="logout()"></app-icon-gear> -->
      <div class="img-container">
        <img src="../../../assets/images/user-thumbnail.png" class="rounded-image" />
      </div>
    </div>
  </nav>
</header>
