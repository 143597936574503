<div class="d-flex pb-3 justify-content-between">
  <span class=" bold-text">All Resources</span>
  <button class="btn btn-primary" (click)="resetForm()" data-bs-toggle="modal" data-bs-target="#resourceModal">
    <app-icon-resources></app-icon-resources>
    New Resource
  </button>
</div>
<app-resource-list [reloadTable]="shouldReloadTable" (openModal)="openResourceModal($event)"></app-resource-list>


<!-- Create Resource Modal -->
<div class="modal fade" id="resourceModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="resourceModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <form (ngSubmit)="createResource()" #resourceForm="ngForm" novalidate id="resourceForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="resourceModalLabel">{{isUpdating ? 'Edit' : 'Create'}} Resource</h5>
        </div>
        <div class="modal-body">
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="name">Name *</label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="resourceParams.name" #name="ngModel" required [ngClass]="((isResourceFormSubmitted || name.touched) && (name.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(name.touched || isResourceFormSubmitted) && (name.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="type">Type *</label>
              <select name="category" id="category" class="form-select" [(ngModel)]="resourceParams.resourceType" (ngModelChange)="toggleDiscountView()">
                <option value="UNCLASSIFIED">RESOURCE</option>
                <option value="LOGO">LOGO</option>
                <option value="BROCHURE">BROCHURE</option>
                <option value="DISCOUNT">DISCOUNT</option>
              </select>
              <!-- <small class="error-text" *ngIf="(name.touched || isResourceFormSubmitted) && (name.errors?.required)"> {{errorMessages.requiredField}} </small> -->
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Description *</label>
              <ngx-wig id="description" name="description" [(ngModel)]="resourceParams.description" #description="ngModel" required [ngClass]="((isResourceFormSubmitted || description.touched) && (description.errors?.required)) ? 'text-has-error' : ''">
              </ngx-wig>
              <small class="error-text" *ngIf="(description.touched || isResourceFormSubmitted) && (description.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div *ngIf="resourceParams.resourceType !== 'DISCOUNT'">
            <div class="row pb-3">
              <div class="col-sm-12 form-group">
                <label for="title">Featured File</label>
                <ngx-dropzone (change)="onSelect($event)">
                  <ngx-dropzone-label>
                    <div class="d-flex justify-content-center">
                      <img src="../../../assets/images/icons/upload.svg" alt="">
                    </div>
                    <div class="d-flex justify-content-center">
                      Drag and drop or &nbsp;<span class="text-primary"> browse </span>&nbsp; to upload
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
            </div>

            <div class="row pb-3" *ngIf="isUpdating && files.length == 0">
              <div class="col-sm-12">
                <div class="uploaded-file">
                  <p>View Featured File</p>
                  <a [href]="resourceParams?.location" target="_blank" class="pointer">
                    <img src="../../../assets/images/icons/eye-open.svg" style="height: 24px; width: 24px;" alt="">
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="resourceParams.resourceType == 'DISCOUNT'">
            <div class="row pb-3">

            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
