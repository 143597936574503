import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private datatableService: DatatableService) { }

  createCategory(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'categories/staff/create', request);
  }

  updateCategory(request: any): Observable<any> {
    return this.http.put(this.baseUrl + 'categories/staff/update', request);
  }

  getCategory(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl}categories/find/${id}`);
  }

  getCategories(request: any): Observable<any> {
    return this.datatableService.getList(this.baseUrl + 'categories/list', request);
  }

  deleteCategory(request: any): Observable<any> {
    return this.http.request('delete', `${this.baseUrl}categories/staff/delete`, {body: request});
  }
}
