import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DeleteModalComponent } from 'src/app/features/delete-modal/delete-modal.component';
import { dateTimePickerOption } from '../../shared/others/datepicker-settings';
import { HeadStartService } from '../../shared/services/head-start.service';
declare var $: any;

@Component({
  selector: 'app-head-start',
  templateUrl: './head-start.component.html',
  styleUrls: ['./head-start.component.css'],
})
export class HeadStartComponent implements OnInit {
  calendar: any[] = [];
  activeDate: any = {};
  model: any = [];
  checkedIds: number[] = [];
  checkAllState: boolean = false;
  deleteModalControl: any;
  selectedCalendar: any = {};
  modelId: number = 0;

  setActiveDateParams: any = {};
  setCalendarParams: any = {};
  isUpdatingActiveDate: boolean = false;
  isUpdatingCalendar: boolean = false;
  //yearQuarter: string = '';
  
  @ViewChild(DeleteModalComponent) deleteModalComponent;
  @Input() reloadTable: any;

  constructor(
    private headStartService: HeadStartService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private datePipe: DatePipe
  ) {}

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnInit(): void {
    this.activeDate = this.activatedRoute.snapshot.data.activeDate.data;
    this.calendar = this.activatedRoute.snapshot.data.calendar.data.data;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      processing: true,
      ajax: (request: any, callback) => {
        this.headStartService.getCalendarDates().subscribe((data: any) => {
          this.calendar = data.data.data;
          callback({
            recordsTotal: data.data.total,
            recordsFiltered: data.data.total,
            data: [],
          });
        });
      }
    };

    this.resetForm();
  }

  ngOnChanges(): void {
      this.reloadDataTable();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  reloadDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
      dtInstance.draw();
    });
  }

  resetForm(): void {
    let that = this;
    //this.yearQuarter = '';
    this.setCalendarParams = {};
    const startDate = $('#startDateTime');
    const endDate = $('#endDateTime');

    const registrationOpenDate = $('#registrationStart');
    const registrationCloseDate = $('#registrationEnd');
    const trainingStartDate = $('#trainingStart');
    const trainingEndDate = $('#trainingEnd');

    startDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setActiveDateParams.startDate = new Date(
          startDate.datetimepicker('getUTCDate')
        );

        const startDateTime = new Date(
          that.setActiveDateParams.startDate
        ).getTime();
        const endDateTime = new Date(
          that.setActiveDateParams.endDate
        ).getTime();
        if (startDateTime > endDateTime) {
          endDate.datetimepicker(
            'setStartDate',
            new Date(that.setActiveDateParams.startDate)
          );
          endDate.datetimepicker(
            'setDate',
            new Date(that.setActiveDateParams.startDate)
          );
        } else {
          endDate.datetimepicker(
            'setStartDate',
            new Date(that.setActiveDateParams.startDate)
          );
        }
      })
      .datetimepicker('setStartDate', new Date())
      .datetimepicker('setDate', new Date());

    endDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setActiveDateParams.endDate = new Date(
          endDate.datetimepicker('getUTCDate')
        );

        const startDateTime = new Date(
          that.setActiveDateParams.startDate
        ).getTime();
        const endDateTime = new Date(
          that.setActiveDateParams.endDate
        ).getTime();

        if (startDateTime > endDateTime) {
          startDate.datetimepicker(
            'setDate',
            new Date(that.setActiveDateParams.endDate)
          );
        }
      })
      .datetimepicker('setStartDate', that.setActiveDateParams.startDate)
      .datetimepicker('setDate', new Date());

    registrationOpenDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.registrationOpenDate = new Date(
          registrationOpenDate.datetimepicker('getUTCDate')
        );
      })
      // .datetimepicker('setStartDate', new Date())
      .datetimepicker('setDate', new Date());

    registrationCloseDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.registrationCloseDate = new Date(
          registrationCloseDate.datetimepicker('getUTCDate')
        );
      })
      // .datetimepicker(
      //   'setStartDate',
      //   that.setCalendarParams.registrationOpenDate
      // )
      .datetimepicker('setDate', new Date());

    trainingStartDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.trainingStartDate = new Date(
          trainingStartDate.datetimepicker('getUTCDate')
        );
      })
      // .datetimepicker('setStartDate', new Date())
      .datetimepicker('setDate', new Date());

    trainingEndDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.trainingEndDate = new Date(
          trainingEndDate.datetimepicker('getUTCDate')
        );
      })
      // .datetimepicker('setStartDate', that.setCalendarParams.trainingStartDate)
      .datetimepicker('setDate', new Date());
  }

  getActiveDate(): void {
    this.headStartService.getPromotionDates().subscribe((response: any) => {
      if (response.code == 0) {
        this.activeDate = response.data;
      }
    });
  }

  getCalendar(): void {
    this.headStartService.getCalendarDates().subscribe((response: any) => {
      if (response.code == 0) {
        this.calendar = response.data;
      }
    });
  }

  createActiveDate(): void {
    // 2022-01-21T20:02:28.485Z
    // const model = {
    //   startDate: this.datePipe.transform(this.setCalendarParams.startDate, 'yyyy-MM-ddThh:mm:ss.000Z'),
    //   endDate: this.datePipe.transform(this.setActiveDateParams.endDate, 'yyyy-MM-ddThh:mm:ss.000Z'),
    // }
    this.headStartService
      .addPromotionDate(this.setActiveDateParams)
      .subscribe((response: any) => {
        if (response.code == 0) {
          this.toastrService.success(response.message, 'Success!', {
            closeButton: true,
          });
          $('#activeDateModal').modal('hide');
          this.getActiveDate();
        }
      });
  }
  
  errorFields(): boolean {
    const registrationOpenDate = $('#registrationStart');
    const registrationCloseDate = $('#registrationEnd');
    const trainingStartDate = $('#trainingStart');
    const trainingEndDate = $('#trainingEnd');
    const yearQuarterInput = $('#yearQuarter');
    if (this.setCalendarParams.registrationOpenDate == undefined ||
        this.setCalendarParams.registrationCloseDate == undefined ||
        this.setCalendarParams.trainingStartDate == undefined ||
        this.setCalendarParams.trainingEndDate == undefined ||
        this.setCalendarParams.yearQuarter == undefined
      ) {
        if (this.setCalendarParams.registrationOpenDate == undefined) {
          registrationOpenDate[0].classList.add("errorField");
        }
        if (this.setCalendarParams.registrationCloseDate == undefined) {
          registrationCloseDate[0].classList.add("errorField");
        }
        if (this.setCalendarParams.trainingStartDate == undefined) {
          trainingStartDate[0].classList.add("errorField");
        }
        if (this.setCalendarParams.trainingEndDate == undefined) {
          trainingEndDate[0].classList.add("errorField");
        }
        if (this.setCalendarParams.yearQuarter == '') {
          yearQuarterInput[0].classList.add("errorField");
        }
      return true;
    }else {
      registrationOpenDate[0].classList.remove("errorField");
      return false;
    }
  }

  clear(elem) {
    const registrationOpenDate = $('#registrationStart');
    const registrationCloseDate = $('#registrationEnd');
    const trainingStartDate = $('#trainingStart');
    const trainingEndDate = $('#trainingEnd');
    const yearQuarterInput = $('#yearQuarter');
    if (elem == 'regOpen') {
      registrationOpenDate[0].classList.remove("errorField");
    }
    if (elem == 'regClose') {
      registrationCloseDate[0].classList.remove("errorField");
    }
    if (elem == 'trainingStart') {
      trainingStartDate[0].classList.remove("errorField");
    }
    if (elem == 'trainingEnd') {
      trainingEndDate[0].classList.remove("errorField");
    }
    if (elem == 'yearQuarter') {
      yearQuarterInput[0].classList.remove("errorField");
    }

  }

  checkOne(id: any): void {
    (this.checkedIds.indexOf(id) != -1) ? this.checkedIds.splice(this.checkedIds.indexOf(id), 1) : this.checkedIds.push(id);
  }

  checkAll(): void {
    this.checkedIds = [];
    if(!this.checkAllState){
        $('tbody div .form-check-input.check').map((index, obj) =>{
          this.checkOne(parseInt(obj.id))
        });
    }
    this.checkAllState = !this.checkAllState;
  }

  deleteCalendar(value): void {
    this.selectedCalendar = value;
    this.deleteModalComponent.openModal1();
  }

  deleteResponse(value) {
    if (value) {
      const that = this;
      let model: any = [];
      if (this.selectedCalendar != null) {
        model.push(this.selectedCalendar);
      } else {
        model = this.checkedIds;
      }
      // const model = {
      //   ids: ids
      // };
      this.headStartService.deleteCalendar(model).subscribe((response: any) => {
        if (response.code == 0) {
          this.toastrService.success(response.message, 'Success!', {closeButton: true});
          this.reloadDataTable();
          this.deleteModalComponent.closeModal();
          this.checkedIds = [];
        }
      })
    }
  }

  addMore() {
    if (this.errorFields()) {
      return;
    }
    //this.setCalendarParams.yearQuarter = this.yearQuarter;
    this.modelId += 1;
    this.setCalendarParams.id = this.modelId;
    this.model.push(this.setCalendarParams);
    this.resetForm();
  }

  deletePending(key: number) {
    this.model.forEach((value,index)=>{
      if(value.id==key) this.model.splice(index,1);
    });
  }

  addToCalendar(): void {
    if (this.errorFields()) {
      return;
    }
    //this.setCalendarParams.yearQuarter = this.yearQuarter;
    this.model.push(this.setCalendarParams);
    const newArray = this.model.map(({ id, ...item }) => item);
    this.headStartService
      .addDateToCalendar(newArray)
      .subscribe((response: any) => {
        if (response.code == 0) {
          this.toastrService.success(response.message, 'Success!', {
            closeButton: true,
          });
          $('#calendarModal').modal('hide');
          this.reloadDataTable();
          this.getActiveDate();
          this.isUpdatingCalendar = false;
          this.modelId = 0;
          this.model = [];
        }
      });
  }

  openUpdateCalendar(data: any): void {
    const that = this;
    const registrationOpenDate = $('#registrationStart');
    const registrationCloseDate = $('#registrationEnd');
    const trainingStartDate = $('#trainingStart');
    const trainingEndDate = $('#trainingEnd');

    registrationCloseDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.registrationCloseDate = new Date(
          registrationCloseDate.datetimepicker('getUTCDate')
        );
      })
      .datetimepicker('setDate', new Date(data.registrationCloseDate));

    registrationOpenDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.registrationOpenDate = new Date(
          registrationOpenDate.datetimepicker('getUTCDate')
        );
      })
      .datetimepicker('setDate', new Date(data.registrationOpenDate));

    trainingStartDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.trainingStartDate = new Date(
          trainingStartDate.datetimepicker('getUTCDate')
        );
      })
      .datetimepicker('setDate', new Date(data.trainingStartDate));

    trainingEndDate
      .datetimepicker(dateTimePickerOption)
      .on('hide', () => {
        that.setCalendarParams.trainingEndDate = new Date(
          trainingEndDate.datetimepicker('getUTCDate')
        );
      })
      .datetimepicker('setDate', new Date(data.trainingEndDate));

    this.isUpdatingCalendar = true;
    delete data.lastUpdatedTime;
    delete data.dateCreated;
    delete data.dateCreated;
    delete data.updatedById;

    this.setCalendarParams = data;
    $('#calendarModal').modal('show');
  }

  updateCalendar(): void {
    if (this.errorFields()) {
      return;
    }
    const model: any = [];
    model.push(this.setCalendarParams);
    this.headStartService
      .updateCalendar(model)
      .subscribe((response: any) => {
        if (response.code == 0) {
          this.toastrService.success(response.message, 'Success!', {
            closeButton: true,
          });
          $('#calendarModal').modal('hide');
          this.reloadDataTable();
          this.getActiveDate();
          this.isUpdatingCalendar = false;
        }
      });
  }
}
