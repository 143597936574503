import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './features/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { NotifyErrorInterceptor } from './shared/interceptors/notify-error.interceptor';
import { TokenizerInterceptor } from './shared/interceptors/tokenizer.interceptor';
import { LoaderService } from './shared/services/loader.service';
import { LayoutModule } from './layouts/layout.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { AccountsModule } from './pages/accounts/accounts.module';
import { OrganizationsModule } from './pages/organizations/organizations.module';
import { TrainingsModule } from './pages/trainings/trainings.module';
import { CategoriesModule } from './pages/categories/categories.module';
import { CoursesModule } from './pages/courses/courses.module';
import { MaterialsModule } from './pages/materials/materials.module';
import { ResourcesModule } from './pages/resources/resources.module';
import { CommentsModule } from './pages/comments/comments.module';
import { LogosModule } from './pages/logos/logos.module';
import { ImageViewerModalComponent } from './features/image-viewer-modal/image-viewer-modal.component';
import { IconBackComponent } from './icons/icon-back/icon-back.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HeadStartComponent } from './pages/head-start/head-start.component';
import { HeadStartModule } from './pages/head-start/head-start.module';
import { HeadStartListComponent } from './features/head-start-list/head-start-list.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ImageViewerModalComponent,
    IconBackComponent,
    HeadStartListComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularEditorModule,
    LayoutModule,
    DashboardModule,
    AccountsModule,
    OrganizationsModule,
    TrainingsModule,
    CategoriesModule,
    CoursesModule,
    MaterialsModule,
    ResourcesModule,
    CommentsModule,
    LogosModule,
    HeadStartModule,
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotifyErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenizerInterceptor,
      multi: true
    },
    LoaderService,
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
