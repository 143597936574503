<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 d-flex justify-content-between">
      <p class="bold-text">Head Start Program Setup</p>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-12">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="active-date-tab"
            data-bs-toggle="tab"
            data-bs-target="#active-date"
            type="button"
            role="tab"
            aria-controls="active-date"
            aria-selected="true"
          >
            Active Date
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="calendar-tab"
            data-bs-toggle="tab"
            data-bs-target="#calendar"
            type="button"
            role="tab"
            aria-controls="calendar"
            aria-selected="false"
          >
            Calendar
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active py-3"
          id="active-date"
          role="tabpanel"
          aria-labelledby="active-date-tab"
        >
          <div class="d-flex pb-3 justify-content-between">
            <span class="bold-text">Active Date List</span>
            <button
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#activeDateModal"
              (click)="resetForm()"
            >
              Set Active Date
            </button>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table class="table">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>START DATE</td>
                    <td>END DATE</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      {{ activeDate.startDate | date: "dd-MM-yyy - hh:mm:ss" }}
                    </td>
                    <td>
                      {{ activeDate.endDate | date: "dd-MM-yyy - hh:mm:ss" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade py-3"
          id="calendar"
          role="tabpanel"
          aria-labelledby="calendar-tab"
        >
          <div class="d-flex pb-3 justify-content-between">
            <span class="bold-text">Calendar Dates</span>
            <button
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#calendarModal"
              (click)="resetForm()"
            >
              Add Date To Calendar
            </button>
          </div>
          <div class="delete-checked" *ngIf="checkedIds.length > 0">
            <button class="btn btn-danger btn-sm" (click)="deleteCalendar(null)">
              Delete {{checkedIds.length}} Calendar(s)
            </button>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <td>
                      <div class="form-check d-flex justify-content-center">
                        <input type="checkbox" value="" (click)="checkAll()" class="form-check-input">
                      </div>
                    </td>
                    <td>#</td>
                    <td>REGISTRATION OPEN DATE</td>
                    <td>REGISTRATION CLOSE DATE</td>
                    <td>TRAINING START DATE</td>
                    <td>TRAINING END DATE</td>
                    <td>YEAR QUARTER</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of calendar; let i = index">
                    <td>
                      <div class="form-check d-flex justify-content-center">
                        <input type="checkbox" [id]="data.id" (click)="checkOne(data?.id)" value="{{data?.id}}" class="form-check-input check" [checked]="checkAllState">
                      </div>
                    </td>
                    <td (click)="openUpdateCalendar(data)">{{ i + 1 }}</td>
                    <td (click)="openUpdateCalendar(data)">
                      {{
                        data.registrationOpenDate | date: "dd-MM-yyy - hh:mm:ss"
                      }}
                    </td>
                    <td (click)="openUpdateCalendar(data)">
                      {{
                        data.registrationCloseDate
                          | date: "dd-MM-yyy - hh:mm:ss"
                      }}
                    </td>
                    <td (click)="openUpdateCalendar(data)">
                      {{
                        data.trainingStartDate | date: "dd-MM-yyy - hh:mm:ss"
                      }}
                    </td>
                    <td (click)="openUpdateCalendar(data)">
                      {{ data.trainingEndDate | date: "dd-MM-yyy - hh:mm:ss" }}
                    </td>
                    <td (click)="openUpdateCalendar(data)">
                      {{ data.yearQuarter }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="activeDateModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="activeDateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md modal-dialog-scrollable">
    <form novalidate id="activeDateForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="activeDateModalLabel">
            {{ isUpdatingActiveDate ? "Edit" : "Set" }} Date
          </h5>
        </div>
        <div class="modal-body">
          <div class="row pb-3">
            <div class="col-ms-12 form-group">
              <label for="startDateTime">Start Date & Time *</label>
              <input
                type="text"
                id="startDateTime"
                class="form-control"
                name="startDateTime"
              />
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="endDateTime">End Date & Time *</label>
              <input
                type="text"
                id="endDateTime"
                class="form-control"
                name="endDateTime"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="createActiveDate()"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div
  class="modal fade"
  id="calendarModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="calendarModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md modal-dialog-scrollable">
    <form novalidate id="activeDateForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="calendarModalLabel">
            {{ isUpdatingCalendar ? "Edit" : "Add" }} Calendar Date
          </h5>
        </div>
        <div class="modal-body">
          <div class="row mb-3" *ngIf="model.length > 0">
              <div *ngFor="let pending of model" class="pendingCalendar">{{pending.yearQuarter}}
                <span class="close-icon" (click)="deletePending(pending.id)">x</span>
              </div>
          </div>
          <div class="row pb-3">
            <div class="col-ms-12 form-group">
              <label for="registrationStart"
                >Registration Open Date & Time *</label
              >
              <input
                type="text"
                id="registrationStart"
                class="form-control"
                name="registrationStart"
                (click)="clear('regOpen')"
              />
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="registrationEnd"
                >Registration Close Date & Time *</label
              >
              <input
                type="text"
                id="registrationEnd"
                class="form-control"
                name="registrationEnd"
                (click)="clear('regClose')"
              />
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-ms-12 form-group">
              <label for="trainingStart">Training Start Date & Time *</label>
              <input
                type="text"
                id="trainingStart"
                class="form-control"
                name="trainingStart"
                (click)="clear('trainingStart')"
              />
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="trainingEnd">Training End Date & Time *</label>
              <input
                type="text"
                id="trainingEnd"
                class="form-control"
                name="trainingEnd"
                (click)="clear('trainingEnd')"
              />
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="yearQuarter">Year Quarter *</label>
              <input
                type="text"
                id="yearQuarter"
                class="form-control"
                name="yearQuarter"
                [(ngModel)]="setCalendarParams.yearQuarter"
                (click)="clear('yearQuarter')"
              />
            </div>
          </div>
          <div class="row" *ngIf="!isUpdatingCalendar">
            <div class="col-sm-12 d-flex justify-content-end">
              <button
                class="btn"
                (click)="addMore()"
              >
                <span class="add-btn">Add More</span>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            *ngIf="!isUpdatingCalendar"
            type="button"
            class="btn btn-primary"
            (click)="addToCalendar()"
          >
            Submit
          </button>
          <button
            *ngIf="isUpdatingCalendar"
            type="button"
            class="btn btn-primary"
            (click)="updateCalendar()"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-delete-modal [message]="'Are you sure you want to delete this calendar(s)?'" (response)="deleteResponse($event)"></app-delete-modal>