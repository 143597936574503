import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { arrayLength } from 'ngx-custom-validators/src/app/array-length/validator';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css']
})
export class OrganizationListComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @Output() openModal = new EventEmitter<any>();
  @Input() reloadTable: any;
  organizationList: any[] = [];
  deleteModalControl: any;
  selectedOrganization: any = {};
  checkedIds: number[] = [];
  checkAllState: boolean = false;

  @ViewChild(DeleteModalComponent) deleteModalComponent;

  constructor(private organizationService: OrganizationService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      processing: true,
      ajax: (request: any, callback) => {
        this.organizationService.getOrganizations(request).subscribe((data: any) => {
          // console.log(data);
          this.organizationList = data.data.data;
          callback({
            recordsTotal: data.data.total,
            recordsFiltered: data.data.total,
            data: [],
          });
        });
      }
    };
  }

  ngOnChanges(): void {
    if (this.reloadTable) {
      this.reloadDataTable();
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  reloadDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
      dtInstance.draw();
    });
  }

  showModal(value): void {
    this.openModal.emit(value);
  }

  deleteOrganization(value): void {
    this.selectedOrganization = value;
    this.deleteModalComponent.openModal1();
  }

  deleteResponse(value: any) {
    if (value) {
      const that = this;
      let ids: any = [];
      if (this.selectedOrganization != null) {
        ids.push(this.selectedOrganization);
      } else {
        ids = this.checkedIds;
      }
      const model = {
        ids: ids
      };
      this.organizationService.deleteOrganization(model).subscribe((response: any) => {
        if (response.code == 0) {
          this.toastrService.success(response.message, 'Success!', {closeButton: true});
          this.reloadDataTable();
          this.deleteModalComponent.closeModal();
          this.checkedIds = [];
        }
      })
    }
  }

  checkOne(id: any): void {
    (this.checkedIds.indexOf(id) != -1) ? this.checkedIds.splice(this.checkedIds.indexOf(id), 1) : this.checkedIds.push(id);
  }

  checkAll(): void {
    this.checkedIds = [];
    if(!this.checkAllState){
        $('tbody div .form-check-input.check').map((index, obj) =>{
          this.checkOne(parseInt(obj.id))
        });
    }
    this.checkAllState = !this.checkAllState;
  }

}
