import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class NotifyErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastrService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if(event.status == 200) {
              if (event.body?.code == 96) {
                this.toastService.error(event.body?.message, 'Error!', {
                  closeButton: true
                });
              } else if (event?.body?.code == 25) {
                this.toastService.info(event?.body?.message, 'Info!', {
                  closeButton: true
                })
              }
            }
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.router.navigate(['/sign-in']);
            }
            else if (error.status === 500) {
              this.toastService.error('Service is not responding. Please try later', 'Service Error!', {
                closeButton: true
              });
            }
            else {
              this.toastService.error('', error.error.message, {
                closeButton: true
              });
            }
          }
        }
      ));
  }
}
