<div class="d-flex bold-text pb-3">Overview</div>

<div class="row pb-5">
  <div class="col-sm-3">
    <div class="dashboard-card">
      <p class="label">ACCOUNTS</p>
      <p class="count">{{dashbordTiles?.totalUsers}}</p>
      <app-icon-users class="d-flex justify-content-center account-icon"></app-icon-users>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="dashboard-card">
      <p class="label">ORGANIZATIONS</p>
      <p class="count">{{dashbordTiles?.totalOrganizations == null ? 0 : dashbordTiles?.totalOrganizations}}</p>
      <app-icon-organization class="d-flex justify-content-center organization-icon"></app-icon-organization>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="dashboard-card">
      <p class="label">TRAININGS</p>
      <p class="count">{{dashbordTiles?.totalTrainings}}</p>
      <app-icon-trainings class="d-flex justify-content-center trainings-icon"></app-icon-trainings>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="dashboard-card">
      <p class="label">COURSES</p>
      <p class="count">{{dashbordTiles?.totalCourses}}</p>
      <app-icon-courses class="d-flex justify-content-center courses-icon"></app-icon-courses>
    </div>
  </div>
</div>


<app-account-list></app-account-list>
