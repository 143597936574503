import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatatableService {

  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient, private toastrService: ToastrService) { }

  public getList(url: string, request: any): Observable<any> {
    if (request == null){
    }else{
        const page = request.start; // > 0 ? (request.start / request.length) : request.start;
        url += `?offset=${page}&limit=${request.length}&`;
        const columns = request.columns;

        if (request.order){
            let n = 0;
            request.order.forEach((order: any) => {
                if (n < 1)
                    url += `sort-field=${columns[order.column].name}&sort-order=${order.dir.toUpperCase()}&`;
                n++;
            });
        }

        /*request.filters.forEach((filter) => {
          if (filter.value) {
            url += `${filter.name}like=${filter.value}&`;
          }
        });*/

        if (request.hasOwnProperty('search') && request.search.value){
          url += `filter=${request.search.value}&`;
        }

        if (request.extra){
            JSON.parse(request.extra).map((obj: any) => {
                url += `${obj.name}=${obj.value}&`;
            });
        }
    }

    return this.http.get(url).pipe(map((res: any) => {
      const data: any = res;
      if (data.code !== 96){
        // const count = data.data.total;
        return {
          recordsTotal: data.total,
          recordsFiltered: data.total,
          data: data.data,
          draw: 5
        };
      }else{
        // this.toastrService.error(data.message, 'Error!', {closeButton: true});
        return {
          recordsTotal: 0,
          recordsFiltered: 0,
          data: []
        };
      }
    }));

}
}
