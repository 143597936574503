import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashbordTiles: any = {};
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.dashbordTiles = this.activatedRoute.snapshot.data.totalCounts.data;
    // console.log(this.dashbordTiles);
  }

}
