<div class="table-responsive">
  <div class="delete-checked" *ngIf="checkedIds.length > 0">
    <button class="btn btn-danger btn-sm" (click)="deleteOrganization(null)">
      Delete {{checkedIds.length}} Organizations
    </button>
  </div>
  <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
    <thead>
      <tr>
        <th>
          <div class="form-check d-flex justify-content-center">
            <input type="checkbox" value="" (click)="checkAll()" class="form-check-input">
          </div>
        </th>
        <th>NAME</th>
        <th>REGISTERED DATE</th>
        <!-- <th></th> -->
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let data of organizationList">
        <td>
          <div class="form-check d-flex justify-content-center">
            <input type="checkbox" [id]="data.id" (click)="checkOne(data?.id)" value="{{data?.id}}" class="form-check-input check" [checked]="checkAllState">
          </div>
        </td>
        <td>{{data.name}}</td>
        <td>{{data.dateCreated | date: 'dd-MM-yyyy'}}</td>
        <!-- <td *ngIf="checkedIds.length == 0">
          <div class="d-flex justify-content-around">
            <img src="../../../assets/images/icons/edit.svg" alt="" (click)="showModal(data)">
            <img src="../../../assets/images/icons/delete.svg" alt="" (click)="deleteOrganization(data.id)">
          </div>
        </td> -->
      </tr>
    </tbody>
  </table>
</div>

<app-delete-modal [message]="'Are you sure you want to delete this resource?'" (response)="deleteResponse($event)"></app-delete-modal>
