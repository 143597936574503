import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-courses',
  templateUrl: './icon-courses.component.html',
  styleUrls: ['./icon-courses.component.css']
})
export class IconCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
