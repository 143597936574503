<div class="table-responsive">
  <div class="delete-checked" *ngIf="checkedIds.length > 0">
    <button class="btn btn-danger btn-sm" (click)="deleteMaterial(null)">
      Delete {{checkedIds.length}} Materials
    </button>
  </div>

  <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
    <thead>
      <tr>
        <th>
          <div class="form-check d-flex justify-content-center">
            <input type="checkbox" value="" (click)="checkAll()" class="form-check-input">
          </div>
        </th>
        <th>NAME</th>
        <th>DESCRIPTION</th>
        <th *ngIf="checkedIds.length == 0"></th>
      </tr>
    </thead>

    <tbody class="table-body">
      <tr *ngFor="let data of materialList">
        <td>
          <div class="form-check d-flex justify-content-center">
            <input type="checkbox" [id]="data.id" (click)="checkOne(data?.id)" value="{{data?.id}}" class="form-check-input check" [checked]="checkAllState">
          </div>
        </td>
        <td>{{data?.title}}</td>
        <td>{{data?.description | slice: 0:20}} {{data?.description.length > 20 ? '. . .': ''}}</td>
        <td *ngIf="checkedIds.length == 0">
          <div class="d-flex justify-content-around">
            <img src="../../../assets/images/icons/edit.svg" alt="" (click)="showModal(data)">
            <img src="../../../assets/images/icons/delete.svg" alt="" (click)="deleteMaterial(data.id)">
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-delete-modal [message]="'Are you sure you want to delete this resource?'" (response)="deleteResponse($event)"></app-delete-modal>
