import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IconCommentsComponent } from '../icons/icon-comments/icon-comments.component';
import { IconBellComponent } from '../icons/icon-bell/icon-bell.component';
import { IconCategoriesComponent } from '../icons/icon-categories/icon-categories.component';
import { IconCoursesComponent } from '../icons/icon-courses/icon-courses.component';
import { IconDashboardComponent } from '../icons/icon-dashboard/icon-dashboard.component';
import { IconDollarComponent } from '../icons/icon-dollar/icon-dollar.component';
import { IconGearComponent } from '../icons/icon-gear/icon-gear.component';
import { IconInstructionsComponent } from '../icons/icon-instructions/icon-instructions.component';
import { IconLockComponent } from '../icons/icon-lock/icon-lock.component';
import { IconLogosComponent } from '../icons/icon-logos/icon-logos.component';
import { IconOrganizationComponent } from '../icons/icon-organization/icon-organization.component';
import { IconResourcesComponent } from '../icons/icon-resources/icon-resources.component';
import { IconRolesComponent } from '../icons/icon-roles/icon-roles.component';
import { IconTrainingsComponent } from '../icons/icon-trainings/icon-trainings.component';
import { IconUsersComponent } from '../icons/icon-users/icon-users.component';
import { IconMaterialsComponent } from '../icons/icon-materials/icon-materials.component';


@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    IconCommentsComponent,
    IconBellComponent,
    IconCategoriesComponent,
    IconCoursesComponent,
    IconDashboardComponent,
    IconDollarComponent,
    IconGearComponent,
    IconInstructionsComponent,
    IconLockComponent,
    IconLogosComponent,
    IconMaterialsComponent,
    IconOrganizationComponent,
    IconResourcesComponent,
    IconRolesComponent,
    IconTrainingsComponent,
    IconUsersComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    IconCommentsComponent,
    IconBellComponent,
    IconCategoriesComponent,
    IconCoursesComponent,
    IconDashboardComponent,
    IconDollarComponent,
    IconGearComponent,
    IconInstructionsComponent,
    IconLockComponent,
    IconLogosComponent,
    IconMaterialsComponent,
    IconOrganizationComponent,
    IconResourcesComponent,
    IconRolesComponent,
    IconTrainingsComponent,
    IconUsersComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutModule { }
