export const datepickerOption = {
  format: 'dd-mm-yyyy',
  autoclose: true,
  todayHighlight: true
};


export const dateTimePickerOption = {
  format: 'dd MM yyyy - HH:ii P',
  // todayHighlight: true,
  showMeridian: true,
  autoclose: true,
  todayBtn: true,
  pickerPosition: 'bottom-left'
};
