import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-bell',
  templateUrl: './icon-bell.component.html',
  styleUrls: ['./icon-bell.component.css']
})
export class IconBellComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
