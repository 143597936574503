import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css']
})
export class AccountListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  // dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  organizationList = new Array(5);
  constructor() { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: false,
      ordering: false,
      processing: false,
      // ajax: (request: any, callback) => {
      //   this.userService.getUsers(request).subscribe(data => {
      //     this.userList = data.data.data;
      //     callback({
      //       recordsTotal: data.data.total,
      //       recordsFiltered: data.data.total,
      //       data: [],
      //     });
      //   });
      // }
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  // reloadDataTable(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.destroy();
  //     this.dtTrigger.next();
  //     dtInstance.draw();
  //   });
  // }



}
