import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { CustomFormsModule } from 'ngx-custom-validators';
import { ToastrModule } from 'ngx-toastr';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { LayoutModule } from 'src/app/layouts/layout.module';
import { NgxWigModule } from 'ngx-wig';
import { AuthService } from '../../services/auth.service';
import { NgSelect2Module } from 'ng-select2';
import { UploadService } from '../../services/upload.service';
import { StaffService } from '../../services/staff.service';
import { DatabankService } from '../../services/databank.service';
import { DatatableService } from '../../services/datatable.service';
import { MaterialsService } from '../../services/materials.service';
import { DashboardService } from '../../services/dashboard.service';
import { ActiveDateResolver, CalendarResolver, CategoryResolver, CourseResolver, DashboardResolver, InstructorResolver, MaterialsResolver, PermissionsResolver, RolePermissionsResolver, RoleResolver, TrainingEventResolver, TrainingResolver } from '../../resolvers/training.resolver';
import { CommentsService } from '../../services/comments.service';
import { CoursesService } from '../../services/courses.service';
import { TrainingService } from '../../services/training.service';
import { OrganizationService } from '../../services/organization.service';
import { TrainingEventService } from '../../services/training-event.service';
import { OutlineService } from '../../services/outline.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { HeadStartService } from '../../services/head-start.service';

const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [],
  imports: [
    CustomFormsModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    NgxDropzoneModule,
    LayoutModule,
    NgxWigModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule


  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    DataTablesModule,
    ToastrModule,
    NgxDropzoneModule,
    LayoutModule,
    NgxWigModule,
    NgSelect2Module,
    AngularEditorModule,
    NgxMaskModule,
  ],
  providers: [
    AuthService,
    UploadService,
    StaffService,
    DatabankService,
    DatatableService,
    MaterialsService,
    DashboardService,
    CommentsService,
    CoursesService,
    TrainingService,
    TrainingEventService,
    OrganizationService,
    DashboardResolver,
    MaterialsResolver,
    CategoryResolver,
    CourseResolver,
    InstructorResolver,
    TrainingResolver,
    TrainingEventResolver,
    CalendarResolver,
    ActiveDateResolver,
    RoleResolver,
    DatePipe,
    TitleCasePipe,
    PermissionsResolver,
    RolePermissionsResolver,
    OutlineService,
    HeadStartService
  ]
})
export class SharedModule { }
