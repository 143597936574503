import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Select2OptionData } from 'ng-select2';
import { ToastrService } from 'ngx-toastr';
import { ImageViewerModalComponent } from 'src/app/features/image-viewer-modal/image-viewer-modal.component';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { DatabankService } from 'src/app/shared/services/databank.service';
import { OutlineService } from 'src/app/shared/services/outline.service';
import { UploadService } from 'src/app/shared/services/upload.service';

declare var $: any;

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  errorMessages: any = {};

  courseParams: any = {};
  materialList: any[] = [];
  categoryList: any[] = [];

  isCourseFormSubmitted: boolean = false;

  @ViewChild('courseForm') courseForm: any;
  @ViewChild(ImageViewerModalComponent) imageViewModalComponent;


  isUpdating: boolean = false;
  shouldReloadTable: boolean = false;

  files: File[] = [];
  whatYouLearn: any[] = [];
  whatYouLearnItem: String = '';

  courseOutline: any[] = [];
  courseOutlineItem: any = {};
  public courses: Array<Select2OptionData> = [];
  public options: any = {};
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '175px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Fill in outline here',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [

      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
};

  isEditMode: boolean = false;
  whatYouLearnIndexToEdit: number = 0;
  isOutlineEditMode: boolean = false;
  courseOutlineIndexToEdit: number = 0;
  constructor(private databankService: DatabankService, private courseService: CoursesService, private toastrService: ToastrService,
    private uploadService: UploadService, private activatedRoute: ActivatedRoute, private outlineService: OutlineService) {
    this.errorMessages = databankService.messages;
   }

  ngOnInit() {
    this.courseParams.materials = [];
    this.courseParams.categoryIds = [];
    this.materialList = this.activatedRoute.snapshot.data.materials.data.data;
    this.categoryList = this.activatedRoute.snapshot.data.categories.data.data;
    // deleting unnecessary keys
    this.materialList.forEach((element: any) => {
      delete element.dateCreated;
      delete element.imageUrl;
      delete element.lastUpdatedDate;
      delete element.location;
      delete element.description
    });

    // renaming a key
    this.materialList = this.materialList.map(item => {
      return {id: item.id, text: item.title}
    })

    this.categoryList.forEach((element: any) => {
      delete element.dateCreated;
      delete element.lastUpdatedDate;
      delete element.courses;
      delete element.description;
    });

    // console.log(this.categoryList);
     // renaming a key
     this.categoryList = this.categoryList.map(item => {
      return {id: item.id, text: item.name}
    })

    this.options = {
      width: '100%',
      multiple: true,
      tags: true,
      dropdownParent: '#courseModal'
    };
  }

  onSelect(event: any) {
    // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  resetForm(){
    this.courseParams = {};
    this.isCourseFormSubmitted = false;
    this.whatYouLearn = [];
    this.whatYouLearnItem = '';
    this.courseOutline = [];
    this.courseOutlineItem = {};
    this.isUpdating = false;
  }

  async createCourse() {
    this.isCourseFormSubmitted = true;
    const model = Object.assign({}, this.courseParams);

    if (this.courseForm.form.valid) {
      model.categoryId = parseInt(model.categoryId, 10);
      model.materials = model.materials.map((d: any)  => {
        return parseInt(d, 10);
      });
      model.outlines = this.courseOutline;
      model.whatYouLearn = this.whatYouLearn;

      const fileToUpload: FormData = new FormData();
      fileToUpload.append('files', this.files[0]);
      if (this.files.length > 0) {
        model.image = await this.uploadFile(fileToUpload);
      }

      if (this.isUpdating) {
        delete model.category;
        delete model.dateCreated;
        delete model.lastUpdatedDate;

        this.courseService.updateCourse(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#courseModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.courseParams = {};
              this.files = [];
            }, 1000);
          }
        });
      } else {
        this.courseService.createCourse(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#courseModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.courseParams = {};
              this.files = [];
            }, 1000);
          }
        });

      }
    } else {
      console.log('invalid');
    }
  }

  getCourse(value: any): void {
    if (value) {
      this.courseParams = value;
      this.isUpdating = true;
      $('#courseModal').modal('show');
    }
  }

  openCourseModal(value: any) {
    let valueCopy = Object.assign({}, value);
    if (value) {
      console.log(valueCopy);
      valueCopy.categoryIds = valueCopy.categories.map((element: any) => {
        return element.id.toString();
      });
      valueCopy.materials = valueCopy.materialList.map((element: any) => {
          return element.id.toString();
      });
      this.courseParams = valueCopy;
      this.whatYouLearn = this.courseParams.whatYouLearn;
      this.courseOutline = this.courseParams.outlines;
      this.isUpdating = true;
      $('#courseModal').modal('show');
    }
  }

  async uploadFile(fileToUpload: any) {
    let file = '';
    await this.uploadService.uploadFile(fileToUpload).then((response: any) => {
      if (response.code == 0) {
        file = response.data.files[0].file;
      }
    });
    return file;
  }

  openImage() {
    $('#previewModal').modal('show');
    // this.imageViewModalComponent.openModal1(this.courseParams.location);
  }

  addWhatToLearn() {
    if (this.whatYouLearnItem.length > 0) {
      if (this.isEditMode) {
        this.whatYouLearn[this.whatYouLearnIndexToEdit] = this.whatYouLearnItem;
      } else {
        this.whatYouLearn.push(this.whatYouLearnItem);
      }
      this.whatYouLearnItem = '';
    }
  }

  removeWhatYouLearnItem(index) {
    this.whatYouLearn.splice(index, 1);
  }

  addCourseOutline() {
    if (this.courseOutlineItem.title.length > 0 && this.courseOutlineItem.content.length > 0) {
      if (this.isOutlineEditMode) {
        this.courseOutline[this.courseOutlineIndexToEdit] = this.courseOutlineItem;
      } else {
        this.courseOutline.push(this.courseOutlineItem);
      }
      this.courseOutlineItem = {};
    }
  }

  removeCourseOutlineItem(index) {
    var id = this.courseOutline[index].id;

    var model = {
      ids: [id]
    }

    this.outlineService.deleteOutline(model).subscribe((response: any) => {
      if (response.code == 0) {
        this.courseOutline.splice(index, 1);
      }
    })
  }

  editWhatYouLearnItem(index) {
    this.whatYouLearnItem = this.whatYouLearn[index];
    this.whatYouLearnIndexToEdit = index;
    this.isEditMode = true;
  }

  cancelEditMode() {
    this.whatYouLearnItem = '';
    this.isEditMode = false;
  }

  editCourseOutline(index) {
    this.courseOutlineItem = this.courseOutline[index];
    this.courseOutlineIndexToEdit = index;
    this.isOutlineEditMode = true;
  }

  cancelOutlineEditMode() {
    this.courseOutlineItem = {};
    this.isOutlineEditMode = false;
  }
}
