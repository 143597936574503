<div class="d-flex pb-3 justify-content-between">
  <span class=" bold-text">All Materials</span>
  <button class="btn btn-primary" (click)="resetForm()" data-bs-toggle="modal" data-bs-target="#materialModal">
    <app-icon-materials></app-icon-materials>
    New Material
  </button>
</div>
<app-materials-list [reloadTable]="shouldReloadTable" (openModal)="openMaterialModal($event)"></app-materials-list>


<!-- Create Material Modal -->
<div class="modal fade" id="materialModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="materialModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <form (ngSubmit)="createMaterial()" #materialForm="ngForm" novalidate id="materialForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="materialModalLabel">{{isUpdating ? 'Edit' : 'Create'}} Material</h5>
        </div>
        <div class="modal-body">
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Name *</label>
              <input type="text" class="form-control" id="title" name="title" [(ngModel)]="materialParams.title" #title="ngModel" required [ngClass]="((isMaterialFormSubmitted || title.touched) && (title.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(title.touched || isMaterialFormSubmitted) && (title.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Description *</label>
              <ngx-wig id="description" name="description" [(ngModel)]="materialParams.description" #description="ngModel" required [ngClass]="((isMaterialFormSubmitted || description.touched) && (description.errors?.required)) ? 'text-has-error' : ''">

              </ngx-wig>
              <small class="error-text" *ngIf="(description.touched || isMaterialFormSubmitted) && (description.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Featured File</label>
              <ngx-dropzone (change)="onSelect($event)">
                <ngx-dropzone-label>
                  <div class="d-flex justify-content-center">
                    <img src="../../../assets/images/icons/upload.svg" alt="">
                  </div>
                  <div class="d-flex justify-content-center">
                    Drag and drop or &nbsp;<span class="text-primary"> browse </span>&nbsp; to upload
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
          </div>

          <div class="row pb-3" *ngIf="isUpdating && files.length == 0">
            <div class="col-sm-12">
              <div class="uploaded-file">
                <p>View Featured File</p>
                <a [href]="materialParams?.location" target="_blank" class="pointer">
                  <img src="../../../assets/images/icons/eye-open.svg" style="height: 24px; width: 24px;" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
