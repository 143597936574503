<aside class="left-sidebar d-grid pt-md-0 grid-template-rows" style="z-index: 20;">
  <div class="d-md-flex justify-content-center py-3 d-none">
    <img src="../../../assets/images/logo.png" alt="" class="logo">
  </div>
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
          <ul id="sidebarnav">
              <li class="sidebar-item" routerLink="/dashboard" routerLinkActive="selected">
                  <a class="sidebar-link sidebar-link" routerLink="/dashboard" routerLinkActive="active">
                      <app-icon-dashboard class="sidebar-icon" routerLink="/dashboard" routerLinkActive="active"></app-icon-dashboard>
                      <span class="hide-menu">Dashboard</span>
                  </a>
              </li>
              <li class="sidebar-item" routerLink="/accounts" routerLinkActive="selected">
                  <a class="sidebar-link sidebar-link" routerLink="/accounts" routerLinkActive="active">
                      <app-icon-users class="sidebar-icon" routerLink="/accounts" routerLinkActive="active"></app-icon-users>
                      <span class="hide-menu">Accounts</span>
                  </a>
              </li>
              <li class="sidebar-item" routerLink="/staffs" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/staffs" routerLinkActive="active">
                    <app-icon-users class="sidebar-icon" routerLink="/staffs" routerLinkActive="active"></app-icon-users>
                    <span class="hide-menu">Staffs</span>
                </a>
            </li>
            <!-- <li class="sidebar-item" routerLink="/organizations" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/organizations" routerLinkActive="active">
                    <app-icon-organization class="sidebar-icon" routerLink="/organizations" routerLinkActive="active"></app-icon-organization>
                    <span class="hide-menu">Organizations</span>
                </a>
            </li> -->
            <li class="sidebar-item" routerLink="/trainings" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/trainings" routerLinkActive="active">
                    <app-icon-trainings class="sidebar-icon" routerLink="/trainings" routerLinkActive="active"></app-icon-trainings>
                    <span class="hide-menu">Trainings</span>
                </a>
            </li>
            <li class="sidebar-item" routerLink="/training-events" routerLinkActive="selected">
              <a class="sidebar-link sidebar-link" routerLink="/training-events" routerLinkActive="active">
                  <app-icon-trainings class="sidebar-icon" routerLink="/training-events" routerLinkActive="active"></app-icon-trainings>
                  <span class="hide-menu">Events</span>
              </a>
            </li>
              
              <li class="sidebar-item" routerLink="/head-start" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/head-start" routerLinkActive="active">
                    <app-icon-instructors class="sidebar-icon" routerLink="/head-start" routerLinkActive="active"></app-icon-instructors>
                    <span class="hide-menu">Setup Headstart</span>
                </a>
              </li>
              <li class="sidebar-item" routerLink="/categories" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/categories" routerLinkActive="active">
                    <app-icon-categories class="sidebar-icon" routerLink="/categories" routerLinkActive="active"></app-icon-categories>
                    <span class="hide-menu">Categories</span>
                </a>
              </li>
              <li class="sidebar-item" routerLink="/courses" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/courses" routerLinkActive="active">
                    <app-icon-courses class="sidebar-icon" routerLink="/courses" routerLinkActive="active"></app-icon-courses>
                    <span class="hide-menu">Courses</span>
                </a>
              </li>
              <li class="sidebar-item" routerLink="/materials" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/materials" routerLinkActive="active">
                    <app-icon-materials class="sidebar-icon" routerLink="/materials" routerLinkActive="active"></app-icon-materials>
                    <span class="hide-menu">Materials</span>
                </a>
              </li>
              <li class="sidebar-item" routerLink="/resources" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/resources" routerLinkActive="active">
                    <app-icon-resources class="sidebar-icon" routerLink="/resources" routerLinkActive="active"></app-icon-resources>
                    <span class="hide-menu">Resources</span>
                </a>
              </li>
              <li class="sidebar-item" routerLink="/comments" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/comments" routerLinkActive="active">
                    <app-icon-comments class="sidebar-icon" routerLink="/comments" routerLinkActive="active"></app-icon-comments>
                    <span class="hide-menu">Comments</span>
                </a>
              </li>
              <!-- <li class="sidebar-item" routerLink="/logos" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/logos" routerLinkActive="active">
                    <app-icon-logos class="sidebar-icon" routerLink="/logos" routerLinkActive="active"></app-icon-logos>
                    <span class="hide-menu">Logos</span>
                </a>
              </li> -->
              <!-- <li class="sidebar-item" routerLink="/transactions" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/transactions" routerLinkActive="active">
                    <app-icon-dollar class="sidebar-icon" routerLink="/transactions" routerLinkActive="active"></app-icon-dollar>
                    <span class="hide-menu">Transactions</span>
                </a>
              </li> -->
              <!-- <li class="sidebar-item" routerLink="/permissions" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/permissions" routerLinkActive="active">
                    <app-icon-lock class="sidebar-icon" routerLink="/permissions" routerLinkActive="active"></app-icon-lock>
                    <span class="hide-menu">Permissions</span>
                </a>
              </li> -->
              <li class="sidebar-item" routerLink="/roles" routerLinkActive="selected">
                <a class="sidebar-link sidebar-link" routerLink="/roles" routerLinkActive="active">
                    <app-icon-roles class="sidebar-icon" routerLink="/roles" routerLinkActive="active"></app-icon-roles>
                    <span class="hide-menu">Roles</span>
                </a>
              </li>
          </ul>
      </nav>
      <!-- End Sidebar navigation -->
  </div>
  <!-- End Sidebar scroll-->
</aside>
