import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OutlineService {

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  deleteOutline(request: any): Observable<any> {
    return this.http.request('delete', `${this.baseUrl}outline/staff/delete`, {body: request});
  }
}
