import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private datatableService: DatatableService) { }

  createTraining(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'trainings/staff/create', request);
  }

  updateTraining(request: any): Observable<any> {
    return this.http.put(this.baseUrl + 'trainings/staff/update', request);
  }

  getTraining(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl}trainings/find/${id}`);
  }

  getTrainings(request: any): Observable<any> {
    return this.datatableService.getList(this.baseUrl + 'trainings/list', request);
  }

  deleteTraining(request: any): Observable<any> {
    return this.http.request('delete', `${this.baseUrl}trainings/staff/delete`, {body: request});
  }
}
