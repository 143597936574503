import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesRoutingModule } from './courses-routing.module';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { CoursesComponent } from './courses.component';
import { CourseListComponent } from 'src/app/features/course-list/course-list.component';
import { ResourcesModule } from '../resources/resources.module';


@NgModule({
  declarations: [CoursesComponent, CourseListComponent],
  imports: [
    CommonModule,
    CoursesRoutingModule,
    SharedModule,
    ResourcesModule
  ]
})
export class CoursesModule { }
