import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { IconUsersComponent } from 'src/app/icons/icon-users/icon-users.component';
import { LayoutModule } from 'src/app/layouts/layout.module';
import { AccountListComponent } from 'src/app/features/account-list/account-list.component';
import { AccountsModule } from '../accounts/accounts.module';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    LayoutModule,
    AccountsModule
  ]
})
export class DashboardModule { }
