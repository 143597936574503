import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeadStartRoutingModule } from './head-start-routing.module';
import { HeadStartComponent } from './head-start.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { ResourcesModule } from '../resources/resources.module';


@NgModule({
  declarations: [HeadStartComponent],
  imports: [
    CommonModule,
    HeadStartRoutingModule,
    SharedModule,
    NzCheckboxModule,
    ResourcesModule

  ]
})
export class HeadStartModule { }
