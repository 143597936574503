<div class="d-flex pb-3 justify-content-between">
  <span class=" bold-text">All Categories</span>
  <button class="btn btn-primary" (click)="resetForm()" data-bs-toggle="modal" data-bs-target="#categoryModal">
    <app-icon-categories></app-icon-categories>
    Create Category
  </button>
</div>

<app-category-list [reloadTable]="shouldReloadTable" (openModal)="openCategoryModal($event)"></app-category-list>


<!-- Create Category Modal -->
<div class="modal fade" id="categoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <form (ngSubmit)="createCategory()" #categoryForm="ngForm" novalidate id="categoryForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="categoryModalLabel">{{isUpdating ? 'Edit' : 'Create'}} Category</h5>
        </div>
        <div class="modal-body">
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="name">Name *</label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="categoryParams.name" #name="ngModel" required [ngClass]="((isCategoryFormSubmitted || name.touched) && (name.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(name.touched || isCategoryFormSubmitted) && (name.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Description *</label>
              <ngx-wig id="description" name="description" [(ngModel)]="categoryParams.description" #description="ngModel" required [ngClass]="((isCategoryFormSubmitted || description.touched) && (description.errors?.required)) ? 'text-has-error' : ''">
              </ngx-wig>
              <small class="error-text" *ngIf="(description.touched || isCategoryFormSubmitted) && (description.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
