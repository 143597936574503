import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoriesRoutingModule } from './categories-routing.module';
import { CategoriesComponent } from './categories.component';
import { CategoryListComponent } from 'src/app/features/category-list/category-list.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { ResourcesModule } from '../resources/resources.module';


@NgModule({
  declarations: [CategoriesComponent, CategoryListComponent],
  imports: [
    CommonModule,
    CategoriesRoutingModule,
    SharedModule,
    ResourcesModule
  ]
})
export class CategoriesModule { }
