import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommentsRoutingModule } from './comments-routing.module';
import { CommentsComponent } from './comments.component';
import { CommentListComponent } from 'src/app/features/comment-list/comment-list.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { ResourcesModule } from '../resources/resources.module';


@NgModule({
  declarations: [CommentsComponent, CommentListComponent],
  imports: [
    CommonModule,
    CommentsRoutingModule,
    SharedModule,
    ResourcesModule
  ], exports: [
    CommentListComponent
  ]
})
export class CommentsModule { }
