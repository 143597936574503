<div class="container-fluid g-0">
  <div class="login-background">
    <div class="login-container m-auto">
      <div class="d-flex justify-content-center w-100 mb-3 pt-5">
        <img src="../../../assets/images/logo.png" class="login-logo img-responsive" alt="">
      </div>

      <div class="login-body m-auto">
        <div *ngIf="!isForgotPassword">
          <h3 class="text-center"> {{isRequiredToChangePassword ? 'Update Your Password' : 'Let\'s Sign You In'}}</h3>
          <p class="text-center" *ngIf="!isRequiredToChangePassword">Welcome back, you've been missed</p>
        </div>

        <div *ngIf="isForgotPassword" class="pb-3">
          <h4 class="text-center">Reset Password</h4>
        </div>

        <form (ngSubmit)="authenticateUser()" #loginForm="ngForm" novalidate id="loginForm" *ngIf="!isRequiredToChangePassword && !isForgotPassword">
          <div class="row pb-4">
            <div class="col-sm-12 form-group">
              <input type="text" class="form-control" placeholder="Your email" name="email" id="email" [(ngModel)]="loginParams.email" #email="ngModel" ngvemail required
              [ngClass]="((email.errors?.email || email.errors?.required ) && (email.touched || isLoginFormSubmitted)) ? 'text-has-error' : ''">
             <small class="error-text" *ngIf="(email.touched || isLoginFormSubmitted) && (email.errors?.required)">{{errorMessages.requiredField}}</small>
             <small class="error-text" *ngIf="(email.touched || isLoginFormSubmitted) && (email.errors?.email)">{{errorMessages.validEmail}}</small>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-sm-12 form-group">
              <input [type]="isFieldOneTextVisible ? 'text' : 'password'"  class="form-control" id="password" name="password" placeholder="Password" [(ngModel)]="loginParams.password" #password="ngModel" required
              [ngClass]="((isLoginFormSubmitted || password.touched) && password.errors?.required) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(password.touched || isLoginFormSubmitted) && (password.errors?.required)"> {{errorMessages.requiredField}} </small>
              <span class="fa field-icon d-flex justify-self-end" [ngClass]="isFieldOneTextVisible ? 'fa-eye-slash' : 'fa-eye'" id="toggle-Password-span-1" (click)="togglePasswordText(1)"></span>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-sm-12 d-flex justify-content-end">
              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                <label class="form-check-label" for="flexCheckChecked">
                  Remember Me
                </label>
              </div> -->

              <a (click)="toggleForgotPassword()" class="pointer">Forgot Password?</a>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-sm-12 d-grid mx-auto d-block">
              <button class="btn btn-primary">Sign In</button>
            </div>
          </div>
        </form>

        <form (ngSubmit)="changePassword()" #passwordChangeForm="ngForm" id="changePasswordForm" *ngIf="isRequiredToChangePassword && !isForgotPassword">
          <div class="row pb-4">
            <div class="col-sm-12 form-group">
              <input [type]="isFieldTwoTextVisible ? 'text' : 'password'"  class="form-control" id="newPassword" name="newPassword" placeholder="New Password" [(ngModel)]="passwordChangeParams.newPassword" #newPassword="ngModel" required
              [ngClass]="((isPasswordChangeFormSubmitted || newPassword.touched) && newPassword.errors?.required) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(newPassword.touched || isPasswordChangeFormSubmitted) && (newPassword.errors?.required)"> {{errorMessages.requiredField}} </small>
              <span class="fa field-icon d-flex justify-self-end" [ngClass]="isFieldTwoTextVisible ? 'fa-eye-slash' : 'fa-eye'" id="toggle-Password-span-1" (click)="togglePasswordText(2)"></span>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-sm-12 form-group">
              <input [type]="isFieldThreeTextVisible ? 'text' : 'password'"  class="form-control" id="retypePassword" name="retypePassword" placeholder="Retype Password" [(ngModel)]="passwordChangeParams.confirmNewPassword"
               #confirmNewPassword="ngModel" required [equal]="passwordChangeParams.newPassword" [ngClass]="((isPasswordChangeFormSubmitted || confirmNewPassword.touched) && (confirmNewPassword.errors?.required || confirmNewPassword.errors?.equal)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(confirmNewPassword.touched || isPasswordChangeFormSubmitted) && (confirmNewPassword.errors?.required)"> {{errorMessages.requiredField}} </small>
              <small class="error-text" *ngIf="(confirmNewPassword.touched || isPasswordChangeFormSubmitted) && (confirmNewPassword.errors?.equal)"> {{errorMessages.passwordMismatch}} </small>

              <span class="fa field-icon d-flex justify-self-end" [ngClass]="isFieldThreeTextVisible ? 'fa-eye-slash' : 'fa-eye'" id="toggle-Password-span-1" (click)="togglePasswordText(3)"></span>
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 d-flex justify-content-center">
              <a (click)="returnToLogin()" class="pointer">Return to Login</a>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-sm-12 d-grid mx-auto d-block">
              <button class="btn btn-primary">Change Password</button>
            </div>
          </div>
        </form>

        <form #resetPasswordForm="ngForm" id="resetPasswordForm" *ngIf="isForgotPassword">
          <div class="row pb-4">
            <div class="col-sm-12 form-group">
              <input type="text" class="form-control" placeholder="Your email" name="email" id="email" [(ngModel)]="resetPasswordParams.email" [disabled]="isVerificationCodeSent" #email="ngModel" ngvemail required
              [ngClass]="((email.errors?.email || email.errors?.required ) && (email.touched || isResetPasswordFormSubmitted)) ? 'text-has-error' : ''">
             <small class="error-text" *ngIf="(email.touched || isResetPasswordFormSubmitted) && (email.errors?.required)">{{errorMessages.requiredField}}</small>
             <small class="error-text" *ngIf="(email.touched || isResetPasswordFormSubmitted) && (email.errors?.email)">{{errorMessages.validEmail}}</small>
            </div>
          </div>

          <div class="row pb-4" *ngIf="isVerificationCodeSent">
            <div class="col-sm-12 form-group">
              <input type="text"  class="form-control" id="verificationCode" name="verificationCode" placeholder="Verification Code" [(ngModel)]="resetPasswordParams.verificationCode" #verificationCode="ngModel" required
              [ngClass]="((isResetPasswordFormSubmitted || verificationCode.touched) && verificationCode.errors?.required) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(verificationCode.touched || isResetPasswordFormSubmitted) && (verificationCode.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-4" *ngIf="isVerificationCodeSent">
            <div class="col-sm-12 form-group">
              <input [type]="isFieldTwoTextVisible ? 'text' : 'password'"  class="form-control" id="newPassword" name="newPassword" placeholder="New Password" [(ngModel)]="resetPasswordParams.newPassword" #newPassword="ngModel" required
              [ngClass]="((isResetPasswordFormSubmitted || newPassword.touched) && newPassword.errors?.required) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(newPassword.touched || isResetPasswordFormSubmitted) && (newPassword.errors?.required)"> {{errorMessages.requiredField}} </small>
              <span class="fa field-icon d-flex justify-self-end" [ngClass]="isFieldTwoTextVisible ? 'fa-eye-slash' : 'fa-eye'" id="toggle-Password-span-1" (click)="togglePasswordText(2)"></span>
            </div>
          </div>

          <div class="row pb-4" *ngIf="isVerificationCodeSent">
            <div class="col-sm-12 form-group">
              <input [type]="isFieldThreeTextVisible ? 'text' : 'password'"  class="form-control" id="retypePassword" name="retypePassword" placeholder="Retype Password" [(ngModel)]="resetPasswordParams.confirmNewPassword"
               #confirmNewPassword="ngModel" required [equal]="resetPasswordParams.newPassword" [ngClass]="((isResetPasswordFormSubmitted || confirmNewPassword.touched) && (confirmNewPassword.errors?.required || confirmNewPassword.errors?.equal)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(confirmNewPassword.touched || isResetPasswordFormSubmitted) && (confirmNewPassword.errors?.required)"> {{errorMessages.requiredField}} </small>
              <small class="error-text" *ngIf="(confirmNewPassword.touched || isResetPasswordFormSubmitted) && (confirmNewPassword.errors?.equal)"> {{errorMessages.passwordMismatch}} </small>

              <span class="fa field-icon d-flex justify-self-end" [ngClass]="isFieldThreeTextVisible ? 'fa-eye-slash' : 'fa-eye'" id="toggle-Password-span-1" (click)="togglePasswordText(3)"></span>
            </div>
          </div>

          <div class="row pb-3" *ngIf="isVerificationCodeSent">
            <div class="col-sm-12 d-flex justify-content-center">
              <a (click)="sendVerificationCode()" class="pointer">Didn't receive any code? Click to resend.</a>
            </div>
          </div>

          <div class="row pb-2" *ngIf="!isVerificationCodeSent">
            <div class="col-sm-12 d-grid mx-auto d-block">
              <button type="submit" class="btn btn-primary" (click)="sendVerificationCode()">Send Reset Code</button>
            </div>
          </div>
          <div class="row pb-2" *ngIf="isVerificationCodeSent">
            <div class="col-sm-12 d-grid mx-auto d-block">
              <button type="submit" class="btn btn-primary" (click)="resetPassword()">Reset Password</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
