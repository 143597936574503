import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { ToastrService } from 'ngx-toastr';
import { DatabankService } from 'src/app/shared/services/databank.service';
import { TrainingService } from 'src/app/shared/services/training.service';
import { UploadService } from 'src/app/shared/services/upload.service';

declare var $: any;

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.css']
})
export class TrainingsComponent implements OnInit {

  errorMessages: any = {};

  trainingParams: any = {};
  courseList: any[] = [];

  isTrainingFormSubmitted: boolean = false;

  @ViewChild('trainingForm') trainingForm: any;

  isUpdating: boolean = false;
  shouldReloadTable: boolean = false;

  files: File[] = [];
  public trainings: Array<Select2OptionData> = [];
  public options: any = {};
  // public selectedMaterials: string[] = [];

  constructor(private databankService: DatabankService, private trainingService: TrainingService, private toastrService: ToastrService,
    private uploadService: UploadService, private activatedRoute: ActivatedRoute) {
    this.errorMessages = databankService.messages;
   }

  ngOnInit() {
    this.trainingParams.courses = [];
    this.courseList = this.activatedRoute.snapshot.data.courses.data.data;
    // deleting unnecessary keys
    this.courseList.forEach((element: any) => {
      delete element.dateCreated;
      delete element.imageUrl;
      delete element.lastUpdatedDate;
      delete element.location;
      delete element.description
    });

    // renaming a key
    this.courseList = this.courseList.map(item => {
      return {id: item.id, text: item.name}
    })

    this.options = {
      width: '100%',
      multiple: true,
      tags: true,
      dropdownParent: '#trainingModal'
    };
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  resetForm(){
    this.trainingParams = {};
    this.isTrainingFormSubmitted = false;
    this.isUpdating = false;
  }

  async createTraining() {
    this.isTrainingFormSubmitted = true;
    const model = Object.assign({}, this.trainingParams);

    if (this.trainingForm.form.valid) {
      model.courses = model.courses.map((d: any)  => {
        return parseInt(d, 10);
      });

      const fileToUpload: FormData = new FormData();
      fileToUpload.append('files', this.files[0]);
      if (this.files.length > 0) {
        model.image = await this.uploadFile(fileToUpload);
      }

      if (this.isUpdating) {
        delete model.courseList;
        delete model.dateCreated;
        delete model.lastUpdatedDate;
        delete model.createdBy;

        this.trainingService.updateTraining(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#trainingModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.trainingParams = {};
              this.files = [];
            }, 1000);
          }
        });
      } else {
        this.trainingService.createTraining(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#trainingModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.trainingParams = {};
              this.files = [];
            }, 1000);
          }
        });
      }
    }
  }

  getTraining(value: any): void {
    if (value) {
      this.trainingParams = value;
      this.isUpdating = true;
      $('#trainingModal').modal('show');
    }
  }

  openTrainingModal(value: any) {
    let valueCopy = Object.assign({}, value);
    if (value) {
      valueCopy.courses = valueCopy.courseList.map((element: any) => {
          return element.id.toString();
      });
      this.trainingParams = valueCopy;
      this.isUpdating = true;
      $('#trainingModal').modal('show');
    }
  }

  async uploadFile(fileToUpload: any) {
    let file = '';
    await this.uploadService.uploadFile(fileToUpload).then((response: any) => {
      if (response.code == 0) {
        file = response.data.files[0].file;
      }
    });
    return file;
  }

  openImage() {
    $('#previewModal').modal('show');
    // this.imageViewModalComponent.openModal1(this.courseParams.location);
  }
}
