<div class="d-flex pb-3 justify-content-between">
  <span class=" bold-text">All Trainings</span>
  <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#trainingModal">
    <app-icon-trainings></app-icon-trainings>
    Create Training
  </button>
</div>

<app-training-list [reloadTable]="shouldReloadTable" (openModal)="openTrainingModal($event)"></app-training-list>


<!-- Create Training Modal -->
<div class="modal fade" id="trainingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="trainingModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-scrollable">
    <form (ngSubmit)="createTraining()" #trainingForm="ngForm" novalidate id="trainingForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="trainingModalLabel">Create Training</h5>
        </div>
        <div class="modal-body">
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="name">Name *</label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="trainingParams.name" #name="ngModel" required [ngClass]="((isTrainingFormSubmitted || name.touched) && (name.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(name.touched || isTrainingFormSubmitted) && (name.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Description *</label>
              <textarea id="description" name="description" class="form-control" [(ngModel)]="trainingParams.description" #description="ngModel" required [ngClass]="((isTrainingFormSubmitted || description.touched) && (description.errors?.required)) ? 'text-has-error' : ''">
              </textarea>
              <small class="error-text" *ngIf="(description.touched || isTrainingFormSubmitted) && (description.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="materials">Price</label>
              <input type="text" class="form-control" id="price" name="price" [(ngModel)]="trainingParams.price" prefix="₦" mask="separator.2" thousandSeparator=","
              #price="ngModel" required [ngClass]="((isTrainingFormSubmitted || price.touched) && (price.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(price.touched || isTrainingFormSubmitted) && (price.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="materials">Courses</label><br />

              <ng-select2 name="materials" id="materials" [data]="courseList"
                          [options]="options"
                          [(ngModel)]="trainingParams.courses">
              </ng-select2>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Featured Image</label>
              <ngx-dropzone (change)="onSelect($event)">
                <ngx-dropzone-label>
                  <div class="d-flex justify-content-center">
                    <img src="../../../assets/images/icons/upload.svg" alt="">
                  </div>
                  <div class="d-flex justify-content-center">
                    Drag and drop or &nbsp;<span class="text-primary"> browse </span>&nbsp; to upload
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
          </div>

          <div class="row pb-3" *ngIf="isUpdating && files.length == 0">
            <div class="col-sm-12">
              <div class="uploaded-file">
                <p>View Featured Image</p>
                <!-- <a [href]="trainingParams?.location" target="_blank" class="pointer"> -->
                  <img src="../../../assets/images/icons/eye-open.svg" style="height: 24px; width: 24px;" alt="" class="pointer" (click)="openImage()">
                <!-- </a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>

</div>

<div class="modal fade" id="previewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="previewModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="previewModalLabel">Document Preview</h5>
      </div>
      <div class="modal-body">
        <img src="{{trainingParams?.image}}" alt="" style="width: 100%; height: 400px;">
        <!-- {{courseParams?.image}} -->
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
