import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {


  userPermissions: string[] = [];

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private datatableService: DatatableService) { }

  createRole(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/role/create', request);
  }

  getRoles(request: any): Observable<any> {
    return this.datatableService.getList(this.baseUrl + 'staff/role/list', request);
  }

  getModules(): Observable<any> {
    return this.http.get(this.baseUrl + 'staff/module/find-all');
  }

  updateRole(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/role/update', request);
  }

  updatePermission(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/role/toggle-permission', request);
  }

  getRole(id: any): Observable<any> {
    return this.http.get(this.baseUrl + `staff/role/find/${id}`);
  }

  public hasPermission(permissionCode: string): boolean {
    return (this.userPermissions.includes(permissionCode)) ? true : false;
  }
}
