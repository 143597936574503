import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CategoryService } from "../services/category.service";
import { CoursesService } from "../services/courses.service";
import { DashboardService } from "../services/dashboard.service";
import { HeadStartService } from "../services/head-start.service";
import { MaterialsService } from "../services/materials.service";
import { RoleService } from "../services/role.service";
import { StaffService } from "../services/staff.service";
import { TrainingEventService } from "../services/training-event.service";
import { TrainingService } from "../services/training.service";

@Injectable()
export class DashboardResolver implements Resolve<Observable<any[]>> {
  constructor(private dashboardService: DashboardService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    return this.dashboardService.getTotalCounts();
  }
}


@Injectable()
export class MaterialsResolver implements Resolve<Observable<any[]>> {
  constructor(private materialsService: MaterialsService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    let request: any = { };
    request.length = 100;
    request.start = 0;
    // request.extra = JSON.stringify([{name:'state', value:'APPROVED'}]);

    return this.materialsService.getMaterials(request);
  }
}

@Injectable()
export class CategoryResolver implements Resolve<Observable<any[]>> {
  constructor(private categoryService: CategoryService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    let request: any = { };
    request.length = 100;
    request.start = 0;
    // request.extra = JSON.stringify([{name:'state', value:'APPROVED'}]);

    return this.categoryService.getCategories(request);
  }
}

@Injectable()
export class CourseResolver implements Resolve<Observable<any[]>> {
  constructor(private courseService: CoursesService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    let request: any = { };
    request.length = 100;
    request.start = 0;
    // request.extra = JSON.stringify([{name:'state', value:'APPROVED'}]);

    return this.courseService.getCourses(request);
  }
}

@Injectable()
export class InstructorResolver implements Resolve<Observable<any[]>> {
  constructor(private staffService: StaffService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    let request: any = { };
    request.length = 100;
    request.start = 0;
    // request.extra = JSON.stringify([{name:'state', value:'APPROVED'}]);

    return this.staffService.listStaffs(request);
  }
}

@Injectable()
export class TrainingResolver implements Resolve<Observable<any[]>> {
  constructor(private trainingService: TrainingService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    let request: any = { };
    request.length = 100;
    request.start = 0;
    // request.extra = JSON.stringify([{name:'state', value:'APPROVED'}]);

    return this.trainingService.getTrainings(request);
  }
}

@Injectable()
export class RoleResolver implements Resolve<Observable<any[]>> {
  constructor(private roleService: RoleService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    let request: any = { };
    request.length = 100;
    request.start = 0;
    // request.extra = JSON.stringify([{name:'state', value:'APPROVED'}]);

    return this.roleService.getRoles(request);
  }
}

@Injectable()
export class TrainingEventResolver implements Resolve<Observable<any[]>> {
  constructor(private trainingEventService: TrainingEventService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    const id = route.paramMap.get('id');
    return this.trainingEventService.getTrainingEvent(id);
  }
}

@Injectable()
export class PermissionsResolver implements Resolve<Observable<any[]>> {
  constructor(private roleService: RoleService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    return this.roleService.getModules();
  }
}


@Injectable()
export class RolePermissionsResolver implements Resolve<Observable<any[]>> {
  constructor(private roleService: RoleService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    const id = route.paramMap.get('id');
    return this.roleService.getRole(id);
  }
}

@Injectable()
export class ActiveDateResolver implements Resolve<Observable<any[]>> {
  constructor(private headStartService: HeadStartService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    return this.headStartService.getPromotionDates();
  }
}

@Injectable()
export class CalendarResolver implements Resolve<Observable<any[]>> {
  constructor(private headStartService: HeadStartService, private route: ActivatedRoute) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    return this.headStartService.getCalendarDates();
  }
}