import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit, OnChanges {

  @Input() message: any;
  // @Input() openModal: any;
  @Output() response = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    // console.log(this.openModal);
    // if (this.openModal) {
    //   $('#deleteModal').modal('show');
    // }
  }

  deleteResponse(value: boolean): void {
    this.response.emit(value);
  }

  openModal1(): void {
    $('#deleteModal').modal('show');
  }

  closeModal(): void {
    $('#deleteModal').modal('hide');
  }

}
