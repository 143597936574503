import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isOpen: boolean = false;
  user: any = {};

  constructor(private authService: AuthService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }

  ngOnInit(): void {
  }

  toggleSidebar() {
    $('#main-wrapper').toggleClass('show-sidebar');
  }

  logout(): void{
    this.authService.logout().subscribe((response: any) => {
      console.log(response);
      if (response.code == 0) {
        this.router.navigate(['/login']);
      }
    });
  }

}
