<app-loader></app-loader>
<div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="mini-sidebar" data-boxed-layout="full">

  <app-header *ngIf="showHeader"></app-header>
  <app-sidebar *ngIf="showSidebar" [hasNavigatedToSinglePage]="hasNavigatedToSinglePage"></app-sidebar>

  <div class="overflow" [ngClass]="(currentUrl == '/login' || currentUrl == '/' || currentUrl == '/404') ? '' : 'page-wrapper'">
    <div class="container-fluid p-0" style="margin-bottom: 6rem;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>