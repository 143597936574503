import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialsRoutingModule } from './materials-routing.module';
import { MaterialsComponent } from './materials.component';
import { MaterialsListComponent } from 'src/app/features/materials-list/materials-list.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { ResourcesModule } from '../resources/resources.module';


@NgModule({
  declarations: [MaterialsComponent, MaterialsListComponent],
  imports: [
    CommonModule,
    MaterialsRoutingModule,
    SharedModule,
    ResourcesModule
  ]
})
export class MaterialsModule { }
