import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrainingsRoutingModule } from './trainings-routing.module';
import { TrainingsComponent } from './trainings.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { TrainingListComponent } from 'src/app/features/training-list/training-list.component';
import { ResourcesModule } from '../resources/resources.module';


@NgModule({
  declarations: [
    TrainingsComponent, TrainingListComponent
  ],
  imports: [
    CommonModule,
    TrainingsRoutingModule,
    SharedModule,
    ResourcesModule
  ]
})
export class TrainingsModule { }
