import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root',
})
export class HeadStartService {
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private datatableService: DatatableService
  ) {}

  addPromotionDate(request: any): Observable<any> {
    return this.http.put(this.baseUrl + 'promotion/date', request);
  }

  getPromotionDates(): Observable<any> {
    return this.http.get(this.baseUrl + 'promotion/date/open');
  }

  updateCalendar(request: any): Observable<any> {
    return this.http.put(this.baseUrl + 'promotion/date/calender/open', request);
  }

  addDateToCalendar(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'promotion/date/calender/open', request);
  }

  deleteCalendar(obj: any): Observable<any> {
    return this.http.request('DELETE',this.baseUrl + 'promotion/date/calender/open', {body: obj});
  }

  getCalendarDates(): Observable<any> {
    return this.http.get(this.baseUrl + 'promotion/date/calender/open');
  }
}
