import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private datatableService: DatatableService) { }

  createCourse(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'course/staff/create', request);
  }

  updateCourse(request: any): Observable<any> {
    return this.http.put(this.baseUrl + 'course/staff/update', request);
  }

  getCourse(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl}course/find/${id}`);
  }

  getCourses(request: any): Observable<any> {
    return this.datatableService.getList(this.baseUrl + 'course/list', request);
  }

  deleteCourse(request: any): Observable<any> {
    return this.http.request('delete', `${this.baseUrl}course/staff/delete`, {body: request});
  }
}
