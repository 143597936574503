import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LoaderService } from './shared/services/loader.service';
import { RoleService } from './shared/services/role.service';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  hasNavigatedToSinglePage: boolean = false;
  excemptedPages: string[] = ['/manage-single-products'];
  currentUrl: string = '';

  constructor(public roleService: RoleService, private router: Router, private activatedRoute: ActivatedRoute, private loaderService: LoaderService) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loaderService.show();
      }

      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        if (this.excemptedPages.includes(this.currentUrl)) {
          this.hasNavigatedToSinglePage = false;
        } else {
          this.hasNavigatedToSinglePage = true;
        }
        this.showHeader = this.activatedRoute?.firstChild?.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute?.firstChild?.snapshot.data.showSidebar !== false;
        this.showFooter = this.activatedRoute?.firstChild?.snapshot.data.showFooter !== false;
        this.loaderService.hide();
        this.closeSidebar();
      }
    });

  }

  closeSidebar() {
    $('#main-wrapper').removeClass('show-sidebar')
  }
}
