import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-logos',
  templateUrl: './icon-logos.component.html',
  styleUrls: ['./icon-logos.component.css']
})
export class IconLogosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
