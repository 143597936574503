import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { TrainingService } from 'src/app/shared/services/training.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.css']
})
export class TrainingListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @Output() openModal = new EventEmitter<any>();
  @Input() reloadTable: any;
  trainingList: any[] = [];
  deleteModalControl: any;
  selectedTraining: any = {};
  checkedIds: number[] = [];
  checkAllState: boolean = false;

  @ViewChild(DeleteModalComponent) deleteModalComponent;

  constructor(private trainingService: TrainingService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      processing: true,
      ajax: (request: any, callback) => {
        this.trainingService.getTrainings(request).subscribe((data: any) => {
          this.trainingList = data.data.data;
          callback({
            recordsTotal: data.data.total,
            recordsFiltered: data.data.total,
            data: [],
          });
        });
      }
    };
  }

  ngOnChanges(): void {
    if (this.reloadTable) {
      this.reloadDataTable();
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  reloadDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
      dtInstance.draw();
    });
  }

  showModal(value): void {
    this.openModal.emit(value);
  }

  deleteTraining(value): void {
    this.selectedTraining = value;
    this.deleteModalComponent.openModal1();
  }

  deleteResponse(value: any) {
    if (value) {
      const that = this;
      let ids: any = [];
      if (this.selectedTraining != null) {
        ids.push(this.selectedTraining);
      } else {
        ids = this.checkedIds;
      }
      const model = {
        ids: ids
      };
      this.trainingService.deleteTraining(model).subscribe((response: any) => {
        if (response.code == 0) {
          this.toastrService.success(response.message, 'Success!', {closeButton: true});
          this.reloadDataTable();
          this.deleteModalComponent.closeModal();
          this.checkedIds = [];
        }
      })
    }
  }

  checkOne(id: any): void {
    (this.checkedIds.indexOf(id) != -1) ? this.checkedIds.splice(this.checkedIds.indexOf(id), 1) : this.checkedIds.push(id);
  }

  checkAll(): void {
    this.checkedIds = [];
    if(!this.checkAllState){
        $('tbody div .form-check-input.check').map((index, obj) =>{
          this.checkOne(parseInt(obj.id))
        });
    }
    this.checkAllState = !this.checkAllState;
  }
}
