import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DatabankService } from 'src/app/shared/services/databank.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { UploadService } from 'src/app/shared/services/upload.service';

declare var $: any;
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  errorMessages: any = {};

  resourceParams: any = {};

  isResourceFormSubmitted: boolean = false;

  @ViewChild('resourceForm') resourceForm: any;

  isUpdating: boolean = false;
  shouldReloadTable: boolean = false;

  files: File[] = [];


  constructor(private databankService: DatabankService, private resourceService: ResourcesService, private toastrService: ToastrService,
    private uploadService: UploadService) {
    this.errorMessages = databankService.messages;
   }

  ngOnInit(): void {
  }

  resetForm(){
    this.resourceParams = {};
    this.isResourceFormSubmitted = false;
    this.isUpdating = false;
  }

  async createResource() {
    this.isResourceFormSubmitted = true;

    const model = Object.assign({}, this.resourceParams);

    if (this.resourceForm.form.valid) {
      const fileToUpload: FormData = new FormData();
      fileToUpload.append('files', this.files[0]);
      if (this.files.length > 0) {
        model.location = await this.uploadFile(fileToUpload);
      }
      if (this.isUpdating) {
        this.resourceService.updateResource(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#resourceModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.resourceParams = {};
              this.files = [];
            }, 1000);
          }
        });
      }
      else {
        this.resourceService.createResource(model).subscribe((createResponse: any) => {
          if (createResponse.code == 0) {
            this.toastrService.success(createResponse.message, 'Success!', {closeButton: true});
            $('#resourceModal').modal('hide');
            this.shouldReloadTable = true;
            setTimeout(() => {
              this.resourceParams = {};
              this.files = [];
            }, 1000);
          }
        });
      }
    }
  }

  getResource(value: any): void {
    if (value) {
      this.resourceParams = value;
      this.isUpdating = true;
      $('#resourceModal').modal('show');
    }
  }

  onSelect(event: any) {
    // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  openResourceModal(value: any) {
    if (value) {
      this.resourceParams = value;
      this.isUpdating = true;
      $('#resourceModal').modal('show');
    }
  }

  async uploadFile(fileToUpload: any) {
    let file = '';
    await this.uploadService.uploadFile(fileToUpload).then((response: any) => {
      if (response.code == 0) {
        file = response.data.files[0].file;
      }
    });
    return file;
  }

  toggleDiscountView(): void {
    // console.log(this.resourceParams.resourceType);
  }
}
