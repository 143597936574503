<div class="d-flex pb-3 justify-content-between">
  <span class=" bold-text">All Courses</span>
  <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#courseModal" (click)="resetForm()">
    <app-icon-courses></app-icon-courses>
    New Course
  </button>
</div>

<app-course-list [reloadTable]="shouldReloadTable" (openModal)="openCourseModal($event)"></app-course-list>

<div class="modal fade" id="courseModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="courseModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <form #courseForm="ngForm" novalidate id="courseForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="courseModalLabel">{{isUpdating ? 'Edit' : 'Create'}} Course</h5>
        </div>
        <div class="modal-body">
          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="name">Name *</label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="courseParams.name" #name="ngModel" required [ngClass]="((isCourseFormSubmitted || name.touched) && (name.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(name.touched || isCourseFormSubmitted) && (name.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Description *</label>
              <textarea  id="description" name="description" class="form-control" cols="30" rows="10" [(ngModel)]="courseParams.description"
              #description="ngModel" required [ngClass]="((isCourseFormSubmitted || description.touched) && (description.errors?.required)) ? 'text-has-error' : ''">
              </textarea>
              <small class="error-text" *ngIf="(description.touched || isCourseFormSubmitted) && (description.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-6 form-group">
              <label for="index">Position Index *</label>
              <input type="text" class="form-control" id="index" name="index" [(ngModel)]="courseParams.index" #index="ngModel" required [ngClass]="((isCourseFormSubmitted || index.touched) && (index.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(index.touched || isCourseFormSubmitted) && (index.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
            <div class="col-sm-6 form-group">
              <label for="price">Price</label>
              <input type="text" class="form-control" prefix="₦" mask="separator.2" thousandSeparator="," id="price" name="price" [(ngModel)]="courseParams.price" #price="ngModel" required
              [ngClass]="((isCourseFormSubmitted || price.touched) && (price.errors?.required)) ? 'text-has-error' : ''">
              <small class="error-text" *ngIf="(price.touched || isCourseFormSubmitted) && (price.errors?.required)"> {{errorMessages.requiredField}} </small>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12">
              <label for="category">Category</label><br />
              <ng-select2 name="category" id="category" [data]="categoryList"
                          [options]="options"
                          [(ngModel)]="courseParams.categoryIds">
              </ng-select2>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="materials">Materials</label><br />

              <ng-select2 name="materials" id="materials" [data]="materialList"
                          [options]="options"
                          [(ngModel)]="courseParams.materials">
              </ng-select2>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-sm-8 form-group">
              <label for="name">What you'll learn *</label>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <div class="">
                      <div class="d-flex justify-content-between p-2 w-100" >
                        <div style="width: 75%;">
                          <input type="text" class="form-control" id="whatYouLearnItem" name="whatYouLearnItem" [(ngModel)]="whatYouLearnItem" [ngClass]="whatYouLearn.length == 0 ? 'text-has-error' : ''">
                        </div>
                        <div style="width: 25%;" class="d-flex justify-content-around">
                          <button class="btn btn-secondary" (click)="cancelEditMode()">Cancel</button>
                          <button class="btn btn-primary" (click)="addWhatToLearn()">{{isEditMode ? 'Update' : 'Add'}}</button>
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="what-you-learn-items">
                        <div class="uploaded-file mb-2" *ngFor="let item of whatYouLearn; let i = index;">
                          <p>{{item | slice: 0:80}} {{item.length > 80 ? '...': ''}}</p>
                          <div class="d-flex justify-content-between" style="width: 80px;">
                            <img src="../../../assets/images/icons/edit.svg" class="pointer"  style="height: 24px; width: 24px;" (click)="editWhatYouLearnItem(i)" alt="">
                            <img src="../../../assets/images/icons/delete.svg" class="pointer" style="height: 24px; width: 24px;" (click)="removeWhatYouLearnItem(i)" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row pb-3">
            <div class="col-sm-12">
              <label for="">Course Outline</label>
              <input type="text" class="form-control mb-3" id="courseOutlineTitle" name="courseOutlineTitle" [(ngModel)]="courseOutlineItem.title" [ngClass]="courseOutline.length == 0 ? 'text-has-error' : ''">
              <angular-editor id="content" name="content" class="no-resize" [(ngModel)]="courseOutlineItem.content" [ngClass]="courseOutline.length == 0 ? 'text-has-error' : ''" [config]="editorConfig"></angular-editor>

              <!-- <ngx-wig id="content" name="content" [(ngModel)]="courseOutlineItem.content"  [ngClass]="courseOutline.length == 0 ? 'text-has-error' : ''"> -->
              <!-- </ngx-wig> -->
            </div>
            <div class="col-sm-12 d-flex justify-content-end pt-3">
              <button class="btn btn-secondary" style="margin-right: 20px;" (click)="cancelOutlineEditMode()">Cancel</button>
              <button class="btn btn-primary" (click)="addCourseOutline()">{{isOutlineEditMode ? 'Update' : 'Add'}}</button>
            </div>
          </div>

          <div class="what-you-learn-items">
            <div class="uploaded-file mb-2" *ngFor="let item of courseOutline; let i = index;">
              <p>{{item.title}}</p>
              <div class="d-flex justify-content-between" style="width: 80px;">
                <img src="../../../assets/images/icons/edit.svg" class="pointer"  style="height: 24px; width: 24px;" (click)="editCourseOutline(i)" alt="">
                <img src="../../../assets/images/icons/delete.svg" class="pointer" style="height: 24px; width: 24px;" (click)="removeCourseOutlineItem(i)" alt="">
              </div>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-sm-12 form-group">
              <label for="title">Featured Image</label>
              <ngx-dropzone (change)="onSelect($event)">
                <ngx-dropzone-label>
                  <div class="d-flex justify-content-center">
                    <img src="../../../assets/images/icons/upload.svg" alt="">
                  </div>
                  <div class="d-flex justify-content-center">
                    Drag and drop or &nbsp;<span class="text-primary"> browse </span>&nbsp; to upload
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
          </div>

          <div class="row pb-3" *ngIf="isUpdating && files.length == 0">
            <div class="col-sm-12">
              <div class="uploaded-file">
                <p>View Featured Image</p>
                <!-- <a [href]="courseParams?.location" target="_blank" class="pointer"> -->
                  <img src="../../../assets/images/icons/eye-open.svg" style="height: 24px; width: 24px;"
                   alt="" (click)="openImage()" class="pointer">
                <!-- </a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary" (click)="createCourse()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>


<div class="modal fade" id="previewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="previewModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="previewModalLabel">Document Preview</h5>
      </div>
      <div class="modal-body">
        <img src="{{courseParams?.image}}" alt="" style="width: 100%; height: 400px;">
        <!-- {{courseParams?.image}} -->
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
