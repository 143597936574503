import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  login(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/open/login', request);
  }

  logout(): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/logout', {});
  }

  changePassword(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/change-password', request);
  }

  sendVerificationCode(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/open/verification-code', {
      email: request.email
    });
  }

  resetPassword(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/open/reset-password', request);
  }
}
