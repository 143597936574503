import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationsRoutingModule } from './organizations-routing.module';
import { OrganizationsComponent } from './organizations.component';
import { OrganizationListComponent } from 'src/app/features/organization-list/organization-list.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { ResourcesModule } from '../resources/resources.module';


@NgModule({
  declarations: [
    OrganizationsComponent,
    OrganizationListComponent
  ],
  imports: [
    CommonModule,
    OrganizationsRoutingModule,
    ResourcesModule,
    SharedModule
  ]
})
export class OrganizationsModule { }
