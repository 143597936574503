<!-- loading overlay -->
<div class="transparent-overlay" id="loading-overlay">
  <div class="loading-img">
    <div class="loadingio-spinner-double-ring-n3pl24acwlf"><div class="ldio-rv78fcsfbrf">
      <div></div>
      <div></div>
      <div><div></div></div>
      <div><div></div></div>
    </div></div>
  </div>
</div>
<!--/ loading overlay -->
