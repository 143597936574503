import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private datatableService: DatatableService) { }

  uploadFile(request: any): Promise<any> {
    return this.http.post(this.baseUrl + 'files/staff/files', request).toPromise();
  }
}
