import { Component, Input, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-image-viewer-modal',
  templateUrl: './image-viewer-modal.component.html',
  styleUrls: ['./image-viewer-modal.component.css']
})
export class ImageViewerModalComponent implements OnInit {


  link: any;
  constructor() { }

  ngOnInit(): void {
  }

  openModal1(message: any): void {
    this.link = message;
    $('#previewModal').modal('show');
  }

  closeModal(): void {
    $('#previewModal').modal('hide');
  }

}
