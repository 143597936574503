import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginModule } from './pages/login/login.module';
import {
  ActiveDateResolver,
  CalendarResolver,
  CategoryResolver,
  CourseResolver,
  DashboardResolver,
  InstructorResolver,
  MaterialsResolver,
  PermissionsResolver,
  RolePermissionsResolver,
  RoleResolver,
  TrainingEventResolver,
  TrainingResolver,
} from './shared/resolvers/training.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    loadChildren: () => LoginModule,
    data: {
      showHeader: false,
      showSidebar: false,
      showFooter: false,
    },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    resolve: {
      totalCounts: DashboardResolver,
    },
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./pages/accounts/accounts.module').then((m) => m.AccountsModule),
  },
  {
    path: 'staffs',
    loadChildren: () =>
      import('./pages/staffs/staffs.module').then((m) => m.StaffsModule),
    resolve: {
      roles: RoleResolver,
    },
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('./pages/organizations/organizations.module').then(
        (m) => m.OrganizationsModule
      ),
  },
  {
    path: 'trainings',
    loadChildren: () =>
      import('./pages/trainings/trainings.module').then(
        (m) => m.TrainingsModule
      ),
    resolve: {
      courses: CourseResolver,
    },
  },
  {
    path: 'training-events',
    loadChildren: () =>
      import('./pages/training-event/training-event.module').then(
        (m) => m.TrainingEventModule
      ),
    resolve: {
      trainings: TrainingResolver,
      instructors: InstructorResolver,
    },
  },
  {
    path: 'training-event/:id',
    loadChildren: () =>
      import('./pages/training-event-item/training-event-item.module').then(
        (m) => m.TrainingEventItemModule
      ),
    resolve: {
      trainingEvent: TrainingEventResolver,
    },
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./pages/categories/categories.module').then(
        (m) => m.CategoriesModule
      ),
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./pages/courses/courses.module').then((m) => m.CoursesModule),
    resolve: {
      materials: MaterialsResolver,
      categories: CategoryResolver,
    },
  },
  {
    path: 'materials',
    loadChildren: () =>
      import('./pages/materials/materials.module').then(
        (m) => m.MaterialsModule
      ),
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('./pages/resources/resources.module').then(
        (m) => m.ResourcesModule
      ),
  },
  {
    path: 'comments',
    loadChildren: () =>
      import('./pages/comments/comments.module').then((m) => m.CommentsModule),
  },
  {
    path: 'logos',
    loadChildren: () =>
      import('./pages/logos/logos.module').then((m) => m.LogosModule),
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./pages/roles/roles.module').then((m) => m.RolesModule),
  },
  {
    path: 'head-start',
    loadChildren: () =>
      import('./pages/head-start/head-start.module').then(
        (m) => m.HeadStartModule
      ),
      resolve: {
        activeDate: ActiveDateResolver,
        calendar: CalendarResolver,
      },
  },
  {
    path: 'role/permissions/:id',
    loadChildren: () =>
      import('./pages/permissions/permissions.module').then(
        (m) => m.PermissionsModule
      ),
    resolve: {
      permissions: PermissionsResolver,
      rolePermissions: RolePermissionsResolver,
    },
  },
  {
    path: '404',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
    data: {
      showHeader: false,
      showSidebar: false,
      showFooter: false,
    },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
