<div class="table-responsive">
  <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
    <thead>
      <tr>
        <th>
          <div class="form-check d-flex justify-content-center">
            <input type="checkbox" value="" id="flexCheckDefault" class="form-check-input">
          </div>
        </th>
        <th>FIRST NAME</th>
        <th>MIDDLE NAME</th>
        <th>LAST NAME</th>
        <th>EMAIL</th>
        <th>COMPANY</th>
        <th>JOINED</th>
        <!-- <th></th> -->
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of organizationList">
        <td>
          <div class="form-check d-flex justify-content-center">
            <input type="checkbox" value="" id="flexCheckDefault" class="form-check-input">
          </div>
        </td>
        <td>Temiloluwa</td>
        <td>Dare</td>
        <td>Dami</td>
        <td>dabbyt@gmail.com</td>
        <td>First Bank</td>
        <td>29 May, 2019</td>

        <!-- <td>
          <div class="d-flex justify-content-around">
            <img src="../../../assets/images/icons/edit.svg" alt="">
            <img src="../../../assets/images/icons/delete.svg" alt="">
          </div>
        </td> -->
      </tr>
    </tbody>
  </table>
</div>


