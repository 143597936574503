import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatatableService } from './datatable.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private datatableService: DatatableService) { }

  createStaff(request: any): Observable<any> {
    return this.http.post(this.baseUrl + 'staff/create', request);
  }

  updateStaff(request: any): Observable<any> {
    return this.http.put(this.baseUrl + 'staff/admin/update', request);
  }

  listStaffs(request: any): Observable<any> {
    return this.datatableService.getList(this.baseUrl + 'staff/list', request);
  }

  findStaff(staffId: any): Observable<any> {
    return this.http.get(this.baseUrl + 'staff/find/' + staffId);
  }
}
